import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../styles/countryRegions.module.css";
import { partnerWebsitesList } from "../api";
import { Layout } from "../../../ui";
import { toast } from "react-toastify";

const Partners = () => {
  const navigate = useNavigate();

  const [partnersList, setpartnersList] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    partnerWebsitesList().then((res) => {
      setpartnersList(res?.data)
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      toast.error(error?.data?.message || "Something went wrong!")
    })
  }, []);


  if (loading) {
    return <Layout title="Partens"><div className={styles.loading}>Loading</div></Layout>
  }

  return (
    <Layout title="Partners">
      <div className={styles.PlansList}>
        <div className="row">
          {partnersList && partnersList.map((item:any) => (
            <div key={item?._id} className="col-md-2 cursor-pointer" onClick={()=> navigate(`/partners/${item?.partnerName.toLowerCase()}/${item?._id}`)}>
              {/* */}
              <div className={styles.planslIstinner}>
                <h5>{item?.partnerName}</h5>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Partners;