import { useEffect, useState } from "react";
import styles from "../styles/style.module.css";
import commonStyles from "../../../lib/common/common.module.css";
import { LOCAL_CONSTANTS } from "../../../lib/constants";
import { VfsLocationList } from "../api";
import Layout from "../../../ui/layout";
import { Pagination } from "../../../ui";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const VFSEmployeesList = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const partnerName = queryParams.get("partner_name");
    const { partnerWebsiteId } = useParams();
    const navigate = useNavigate();

    const [pagination, setPagination] = useState({
        skip: 0,
        limit: LOCAL_CONSTANTS.limitCount,
    });

    const [users, setUsers] = useState<any>({
        list: [],
        count: 0,
    });

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const query = {
            partnerWebsiteId: partnerWebsiteId,
            page: (1 + pagination.skip),
            limit: pagination.limit
        }

        VfsLocationList(query).then((res: any) => {
            setLoading(false);
            setUsers({ list: res?.data?.employees, count: res?.data?.count })
        }).catch((error) => {
            setLoading(false);
            toast.error(error?.data?.message || "Something went wrong!")
        })
    }, [pagination]);

    return (
        <Layout title={`VFS Employees List`}>
            <div className={commonStyles.tableData}>
                <div className={styles.scrollTable}>
                    <table>
                        <thead>
                            <tr>
                                <th>Mission Served</th>
                                <th>Product</th>
                                <th>City of Operation</th>
                                <th>Sharing Schema</th>
                                <th>Combined final name</th>
                                <th>Qr Code</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading && (
                                <tr>
                                    <td colSpan={7} align="center" className={styles.loadingText}>
                                        {LOCAL_CONSTANTS.loading}
                                    </td>
                                </tr>
                            )}
                            {!loading && users.list.length === 0 && (
                                <tr>
                                    <td colSpan={7} align="center">
                                        No User Found
                                    </td>
                                </tr>
                            )}
                            {!loading &&
                                users.list.map((item: any) => (
                                    <tr key={item._id}>
                                        <td>{item.missionServed || LOCAL_CONSTANTS.na}</td>
                                        <td>{item.product || LOCAL_CONSTANTS.na}</td>
                                        <td>{item.cityOfOperation || LOCAL_CONSTANTS.na}</td>    
                                        <td>{item.sharingSchema || LOCAL_CONSTANTS.na}</td>
                                        <td>{item.combinedFinalName || LOCAL_CONSTANTS.na}</td>
                                        <td><a className={styles.download_btn} target="_blank" rel="noopener noreferrer" href={item.qrCodeUrl}>Download QR Code</a></td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
                <Pagination
                    paginationState={pagination}
                    setPaginationState={setPagination}
                    count={users.count}
                />
            </div>
        </Layout>
    );
};

export default VFSEmployeesList;
