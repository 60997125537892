import React from "react";
import Modal from "react-bootstrap/Modal";

import styles from "./style.module.css";

interface PopupType {
  show: boolean;
  handleClose: () => void;
  heading: string;
  closeButtonText?: string;
  acceptButtonText?: string;
  content: string;
  handleSubmit: (id: string) => void;
  id: string;
  changeStatus?: boolean;
};

const Popup = (props: PopupType) => {
  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header className={styles.modHead} closeButton>
        <Modal.Title>{props.heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.content}>{props.content}</Modal.Body>
      <Modal.Footer className={styles.modFoter}>
        <button type="button" className={styles.close} onClick={() => props.handleSubmit(props.id)}>{props.closeButtonText || "Close"}</button>
        <button type="button" className={styles.button} onClick={() => props.handleSubmit(props.id)}>{props.acceptButtonText || "Save Changes"}</button>
      </Modal.Footer>
    </Modal>
  );
};

export default Popup;