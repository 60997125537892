import { AnalyticsTypes } from "../../../interfaces";
import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../lib/constants";

export const getAnalyticsCard = (): Promise<AnalyticsTypes.AnalyticsCardResponse> => {
  return axios.get(API_ROUTES.ANALYTICS);
}


export const analyticsRevenueGraph = (): Promise<AnalyticsTypes.RevenueGraphResponse> => {
  return axios.get(API_ROUTES.REVENUE_GRAPH);
}
