import { useEffect, useState } from "react";
import styles from "../styles/style.module.css";
import commonStyles from "../../../lib/common/common.module.css";
import { LOCAL_CONSTANTS } from "../../../lib/constants";
import {
  niyoUpsellRecordsList,
  niyoUpsellSingleRecord,
  partnerAdminList,
} from "../api";
import Layout from "../../../ui/layout";
import { Pagination } from "../../../ui";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IMAGES } from "../../../assets/images";
import moment from "moment";
import { toast } from "react-toastify";

const UpsellRecords = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orderId = queryParams.get("orderId");

  const { partnerName, partnerWebsiteId } = useParams();
  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    skip: 0,
    limit: LOCAL_CONSTANTS.limitCount,
  });
  const [clearFilter, setClearFilter] = useState(false);

  const [activeData, setActiveData] = useState<any>([]);

  const [loading, setLoading] = useState<boolean>(true);

  const [dateFilter, setDateFilter] = useState({
    startDate: "",
    endDate: "",
    filterKey: "",
    submit: false
  });

  const roles = window.localStorage.getItem("admin_roles");
  const routAccess =
    (roles !== null && LOCAL_CONSTANTS.ROLES.NIYO_USER === Number(roles)) ||
    (roles !== null && LOCAL_CONSTANTS.ROLES.VFS_USER === Number(roles));

  useEffect(() => {
    setLoading(true);
    const query: any = {
      page: 1 + pagination.skip,
      limit: pagination.limit,
    };

    if (orderId) {
      query.orderId = orderId;
      niyoUpsellSingleRecord(query)
        .then((res: any) => {
          setLoading(false);
          setActiveData(res?.data);
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.data?.message || "Something went wrong!")
        });
    } else {
      query.partnerWebsiteId = partnerWebsiteId;
      niyoUpsellRecordsList(query).then((res: any) => {
        setLoading(false);
        setActiveData(res?.data?.upsellData)
      }).catch((error) => {
        setLoading(false);
        toast.error(error?.data?.message || "Something went wrong!")
      })
    }
  }, [pagination, clearFilter]);


  const handleFilter = (e: any) => {
    e.preventDefault();
    if (!dateFilter?.filterKey) return toast.error("Filter type is required!")
    if (!dateFilter?.startDate) return toast.error("Start date is required!")
    if (!dateFilter?.endDate) return toast.error("End date is required!")
    if (dateFilter?.startDate > dateFilter?.endDate) return toast.error("End date shuold be grater then or equal from start date")
    setDateFilter({ ...dateFilter, submit: true })
    const query: any = {
      page: 1 + pagination.skip,
      limit: pagination.limit,
      partnerWebsiteId: partnerWebsiteId,
      filterKey: dateFilter?.filterKey?.trim(),
      filterStartDate: dateFilter?.startDate,
      filterEndDate: dateFilter?.endDate
    };
    niyoUpsellRecordsList(query).then((res: any) => {
      setLoading(false);
      setActiveData(res?.data?.upsellData)
    }).catch((error) => {
      setLoading(false);
      toast.error(error?.data?.message || "Something went wrong!")
    })


  }
  const selectStyle: any = {
    "background": "#1a1d1e",
    "border": "1px solid #ffffff17",
    "padding": "7px 10px",
    "border-radius": "5px",
    "color": " #fff",
    "appearance": " none",
    "font-size": " 15px"
  }
  return (
    <Layout title={`Upsell Records`}>
      <>
        <div className={styles.rightFilterTabbing}>
          <div className={styles.formDate}>
            <div className={styles.inputGoup}>
              <label>Filter By</label>
              <select style={selectStyle} onChange={(e) => {
                setDateFilter({ ...dateFilter, filterKey: e.target.value, submit: false })
              }} value={dateFilter?.filterKey}>
                <option value=""> Select filter type</option>
                <option value="upsellDate"> Upsell Date </option>
                <option value="createdDate "> Created Date  </option>
              </select>
            </div>

            <div className={styles.inputGoup}>
              <label>Start Date</label>
              <input
                type="date"
                placeholder="start date"
                onChange={(e) => {
                  setDateFilter({ ...dateFilter, startDate: e.target.value, submit: false })
                }}
                value={dateFilter?.startDate}
              />
            </div>

            <div className={styles.inputGoup}>
              <label>End Date</label>
              <input
                type="date"
                placeholder="end"
                onChange={(e) => {
                  setDateFilter({ ...dateFilter, endDate: e.target.value, submit: false })
                }}
                value={dateFilter?.endDate}
              />
            </div>
            <div className={styles.inputGoup}>
              <input
                type="button"
                className="btn btn-success"
                value="Filter"
                onClick={handleFilter}
              />
            </div>
            <div className={styles.inputGoup}>
              <input
                type="button"
                className="btn btn-success"
                value="Clear"
                onClick={() => {
                  setDateFilter({
                    startDate: "",
                    endDate: "",
                    filterKey: "",
                    submit: false
                  })
                  setClearFilter(!clearFilter)
                }}
              />
            </div>
          </div>
        </div>
        <div className={commonStyles.tableData}>
          <div className={styles.scrollTable}>
            <table>
              <thead>
                <tr>
                  <th>Plan Name</th>
                  <th>Upsell Date</th>
                  <th>Upsell Amount</th>
                  <th>Commision %age</th>
                  <th>Commbitz Amount</th>
                  <th>Niyo Amount</th>
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <tr>
                    <td colSpan={7} align="center" className={styles.loadingText}>
                      {LOCAL_CONSTANTS.loading}
                    </td>
                  </tr>
                )}
                {!loading && activeData?.length == 0 && (
                  <tr>
                    <td colSpan={7} align="center">
                      No records Found
                    </td>
                  </tr>
                )}
                {!loading &&
                  activeData.map((item: any) => (
                    <tr key={item._id}>
                      <td>{item.planName}</td>
                      <td>
                        {moment(item.upsellDate).format(
                          LOCAL_CONSTANTS.dateFormat
                        )}
                      </td>
                      <td>{item.upsellAmount}</td>
                      <td>{item.commission}</td>
                      <td>
                        {item.upsellAmount -
                          (item.upsellAmount * item.commission) / 100}
                      </td>
                      <td>{(item.upsellAmount * item.commission) / 100}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <Pagination
            paginationState={pagination}
            setPaginationState={setPagination}
            count={activeData.count}
          />
        </div>
      </>
    </Layout>
  );
};

export default UpsellRecords;
