import React from "react";
// import { useNavigate, useLocation } from "react-router-dom";

import Topbar from "../topbar";
import Sidebar from "../sidebar";
import styles from "./style.module.css";
import commonStyles from "../../lib/common/common.module.css";
// import useAuth from "../../lib/hooks/useAuth";
import { RootState } from "../../redux/store";
import { useAppSelector } from "../../redux/hooks";
// import { APP_ROUTES } from "../../lib/constants";

const Layout = ({ children, title }: { children: React.ReactNode, title: string }) => {
  // const navigate = useNavigate();
  // const location = useLocation();
  // const { isAuthenticated } = useAuth();

  const { sidebar } = useAppSelector((state: RootState) => state.sidebarReducer);

  // useEffect(() => {
  //   if (!isAuthenticated) navigate(APP_ROUTES.LOGIN);
  //   else navigate(location.pathname);
  // }, [isAuthenticated]);

  return (
    <div className={sidebar ? commonStyles.active : ""}>
      <div className={styles["dashboard-page"]}>
        <Sidebar />
        <div className={commonStyles["right-bar"]}>
          <Topbar title={title} />
          {children}
        </div>
      </div>
    </div>

  );
};

export default Layout;
