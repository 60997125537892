import { PlanTypes, CommonTypes } from "../../../interfaces";
import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../lib/constants";

export const tabList = (): Promise<PlanTypes.HomeTabResponse> => {
  return axios.get(API_ROUTES.HOME_TABS.LIST);
}

export const updateTabList = (tabId: string, payload: PlanTypes.UpdateHomeTabPayload): Promise<CommonTypes.DefaultResponse> => {
  return axios.put(`${API_ROUTES.HOME_TABS.LIST}/${tabId}`, payload);
}

export const getPlans = (query: PlanTypes.PlanListQuery): Promise<PlanTypes.PlanListResponse> => {
  return axios.get(API_ROUTES.PLANS.LIST, { params: query });
}

export const updatePlan = (payload: PlanTypes.PlanUpdatePayload, planId: string): Promise<CommonTypes.DefaultResponse> => {
  return axios.put(`${API_ROUTES.PLANS.UPDATE}/${planId}`, payload);
}

export const updatePlanDetails = (payload: PlanTypes.PlanDetailsUpdatePayload): Promise<CommonTypes.DefaultResponse> => {
  return axios.put(`${API_ROUTES.PLANS.UPDATE}`, payload);
}

export const getCountryRegionsPlanDetails = (query: PlanTypes.CountryRegionsPlanDetailsQuery): Promise<PlanTypes.PlanDetailsResponse> => {
  return axios.get(`${API_ROUTES.PLANS.DETAILS}`, { params: query });
}

export const popularCountriesUpdate = (payload: PlanTypes.UpdatePopularCountriesPayload): Promise<CommonTypes.DefaultResponse> => {
  return axios.put(API_ROUTES.COUNTRIES.POPULAR_COUNTRIES_UPDATE, payload);
}

export const updatePopularCountries = (payload: PlanTypes.UpdatePopularCountriesPayload): Promise<CommonTypes.DefaultResponse> => {
  return axios.put(API_ROUTES.COUNTRIES.UPDATE_POPULAR_COUNTRIES, payload);
}