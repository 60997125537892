import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    title: Yup.string()
        .required('Please enter title')
        .matches(/^[A-Za-z\s]+$/, 'Name contains only alphabetic characters')
        .max(25)
        .test('no-whitespace', 'Full name should not start or end with whitespace', value => {
            if (value) {
                return value === value.trim();
            }
            return true;
        }),
    keywords: Yup.array()
        .of(Yup.string().required('Each keyword is required'))
        .min(1, 'At least one keyword is required'),

    app: Yup.boolean().required('app is required'),
    website: Yup.boolean().required('website is required'),
    description: Yup.string().required('Description is required')
});


export {
    validationSchema
};
