import { NotificationTypes } from "../../../interfaces";
import { CommonTypes } from "../../../interfaces";
import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../lib/constants";


export const list = (params: NotificationTypes.ListNotificationQuery): Promise<NotificationTypes.ListNotificationResponse> => {
  return axios.get(API_ROUTES.NOTIFICATIONS.LIST, { params });
}

export const remove = (notificationId: string): Promise<CommonTypes.DefaultResponse> => {
  return axios.delete(`${API_ROUTES.NOTIFICATIONS.LIST}/${notificationId}`);
}

