import { useState } from 'react';
import { Layout } from "../../../ui";
import styles from "../styles/form.module.css";
import { useLocation, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { createPartnerAdmin, validationSchema } from '../validations';
import { toast } from 'react-toastify';
import { PartnerAdminCreate } from '../api';

function PartnerAdminForm() {

    const { partnerName, id } = useParams();
    const location = useLocation();
    const { partnerWebsiteId } = useParams();
    const [qrCode, setQrCode] = useState("")

    const formik = useFormik({
        initialValues: {
            email: "",
            password:""
        },
        enableReinitialize: true, // Allow reinitialization when props change
        validationSchema: createPartnerAdmin,
        onSubmit: async (values) => {
            const payload: any = {
                email: values.email,
                password:values.password,
                partnerWebsiteId : partnerWebsiteId
            };
            toast.promise(
                PartnerAdminCreate(payload),
                {
                    pending: {
                        render() {
                            return "Trying to create admin...";
                        }
                    },
                    success: {
                        render({ data }: any) {
                            formik.resetForm()
                            return "Admin successfully generated!";
                        }
                    },
                    error: {
                        render({ data }: any) {
                            return data?.data?.message;
                        }
                    },
                }
            );
        },
    });


    return (
        <div>
            <Layout title={`Create Admin for ${partnerName?.toUpperCase()}`}>
                <div className={styles.SeoOuter}>
                    <div className='row'>
                        <div className='col-md-8'>
                            <div className={styles.leftSeo}>
                                {/* <h4>SEO Template</h4> */}
                                <form onSubmit={formik.handleSubmit}>
                                    {/* <div className={styles.FormGroup}>
                                        <label>First Name</label>
                                        <input
                                            type='text'
                                            name="fname"
                                            placeholder='Pleae enter name'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.fname}
                                        />
                                        {formik.touched.fname && formik.errors.fname && (
                                            <div className={styles.error}>{formik.errors.fname}</div>
                                        )}
                                    </div> */}

                                    {/* <div className={styles.FormGroup}>
                                        <label>Last Name</label>
                                        <input
                                            type='text'
                                            placeholder='Pleae enter name'
                                            name='lname'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.lname}
                                        />
                                        {formik.touched.lname && formik.errors.lname && (
                                            <div className={styles.error}>{formik.errors.lname}</div>
                                        )}
                                    </div> */}

                                    {/* <div className={styles.FormGroup}>
                                        <label>Contact Number</label>
                                        <input
                                            type='text'
                                            placeholder='Pleae enter name'
                                            name='phoneNumber'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.phoneNumber}
                                        />
                                        {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                                            <div className={styles.error}>{formik.errors.phoneNumber}</div>
                                        )}
                                    </div> */}

                                    <div className={styles.FormGroup}>
                                        <label>Email</label>
                                        <input
                                            type='text'
                                            placeholder='Pleae enter name'
                                            name='email'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email}
                                        />
                                        {formik.touched.email && formik.errors.email && (
                                            <div className={styles.error}>{formik.errors.email}</div>
                                        )}
                                    </div>

                                    <div className={styles.FormGroup}>
                                        <label>Password</label>
                                        <input
                                            type='password'
                                            placeholder='Pleae enter name'
                                            name='password'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.password}
                                        />
                                        {formik.touched.password && formik.errors.password && (
                                            <div className={styles.error}>{formik.errors.password}</div>
                                        )}
                                    </div>
                                    <div className={styles.FormGroup}>
                                        <input type='submit' value="Create" />
                                    </div>
                                </form>
                            </div>
                            {/* {
                                qrCode && (
                                    <div className={styles.download_main}>
                                        <div className={styles.QR_code}>
                                            <img src={qrCode} alt="" />
                                        </div>
                                        <a href={qrCode} className={styles.download_btn} target="_blank" rel="noopener noreferrer">Download QR</a>
                                    </div>
                                )
                            } */}
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    );
}

export default PartnerAdminForm;
