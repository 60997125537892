import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import CurrencyCodes from "currency-codes";
import DatePicker from "react-datepicker";
import moment from "moment";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Tooltip as ReactTooltip } from "react-tooltip"
import { useNavigate, useParams } from "react-router-dom";

import styles from "../styles/manage.module.css";
import { listCountries, listRegions } from "../../promos/api";
import { createCoupon, specificCoupon, updateCoupon } from "../api";
import { CONSTANTS } from "../../../lib/constants/messages";
import { APP_ROUTES, COUPON_TYPE } from "../../../lib/constants";
import { Layout } from "../../../ui";
import CouponSchema from "../validations";

const ManageCoupon = () => {
  const params = useParams();
  const navigate = useNavigate();
  const selectRegionInputRef = useRef<any>();
  const selectCountryInputRef = useRef<any>();

  const [countries, setCountries] = useState<{ value: string, label: string }[]>([]);
  const [regions, setRegions] = useState<{ value: string, label: string }[]>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const addEditCouponFormik = useFormik({
    initialValues: {
      title: "",
      couponCode: "",
      type: 0,
      regions: [],
      countries: [],
      discount: 0,
      minimumSpendAmount: 0,
      startDate: moment().add(1, "day").toString(),
      endDate: moment().add(3, "days").toString(),
      esims: 0,
      minimumSpendCurrency: "",
      color: "",
      textColor: "",
      frequencyPerUser: 0,
    },
    onSubmit: (values, { setSubmitting }) => {

      let payload: any = {
        title: values.title,
        couponCode: values.couponCode,
        type: values.type,
        discount: values.discount,
        minimumSpendAmount: values.minimumSpendAmount,
        startDate: values.startDate,
        endDate: values.endDate,
        esims: values.esims,
        minimumSpendCurrency: values.minimumSpendCurrency,
        color: values.color,
        textColor: values.textColor,
        frequencyPerUser: values.frequencyPerUser,
      };

      if (values.countries && values.countries.length !== 0) payload["countries"] = values.countries.map((item: any) => item.value);
      else payload["regions"] = values.regions.map((item: any) => item.value);

      if (params.id) {
        payload["couponId"] = params.id;
      }

      toast.promise(
        params.id ? updateCoupon(payload, params.id as string) : createCoupon(payload),
        {
          pending: {
            render() {
              return params.id ? CONSTANTS.COUPON.UPDATING : CONSTANTS.COUPON.CREATING;
            },
          },
          success: {
            render() {
              setSubmitting(false);

              addEditCouponFormik.resetForm();
              navigate(APP_ROUTES.COUPONS);

              return params.id ? CONSTANTS.COUPON.UPDATE_SUCCESS : CONSTANTS.COUPON.CREATE_SUCCESS;
            },
          },
          error: {
            render({ data }: any) {
              setSubmitting(false);

              return params.id ? data.data.message : CONSTANTS.COUPON.CREATE_FAILED;
            },
          },
        });

    },
    validationSchema: CouponSchema,
  });

  useEffect(() => {
    listCountries({ limit: 1000 }).then(({ data }) => {
      const countries = data.countries.map(item => ({ label: item.name, value: item._id }))
      setCountries(countries);
    });

    listRegions().then(({ data }) => {
      const regions = data.regions.map(item => ({ label: item.name, value: item._id }));
      setRegions(regions);
    });
  }, []);

  useEffect(() => {
    if (params.id) {
      specificCoupon(params.id as string).then(res => {
        const { coupon } = res.data;

        if (moment(coupon.startDate).isBefore(moment()) && moment(coupon.endDate).isAfter(moment())) {
          setIsDisabled(true);
          toast.error("You cannot edit this coupon since it has already started");
        }
        else if (moment(coupon.endDate).isBefore(moment())) {
          setIsDisabled(true);
          toast.error("You cannot edit this coupon since it has already ended");
        }

        const countries: any = coupon.countries.map(item => ({ value: item._id, label: item.name }));
        const regions: any = coupon.regions.map(item => ({ value: item._id, label: item.name }));

        addEditCouponFormik.setValues({
          title: coupon.title,
          couponCode: coupon.couponCode,
          type: coupon.type,
          discount: coupon.discount,
          countries: countries,
          minimumSpendCurrency: coupon.minimumSpendCurrency,
          startDate: coupon.startDate,
          endDate: coupon.endDate,
          frequencyPerUser: coupon.frequencyPerUser,
          minimumSpendAmount: coupon.minimumSpend,
          esims: coupon.esims,
          color: coupon.color,
          textColor: coupon.textColor,
          regions: regions,
        });
      });
    }
  }, [params.id]);


  return (
    <Layout title="Coupons">
      <div className={styles.addCoupon}>
        <h4>{params.id ? "Edit" : "Add new"} coupon</h4>
        <form className="mt-3">
          <div className="row">
            <div className="col-md-6">
              <div className={styles.fromGroup}>
                <label>
                  Title
                  {
                    addEditCouponFormik.touched.title && addEditCouponFormik.errors.title ?
                      <span className="error">{addEditCouponFormik.errors.title}</span>
                      :
                      null
                  }
                </label>
                <input
                  className={styles.input}
                  type="text"
                  placeholder="Coupon Title"
                  name="title"
                  onChange={addEditCouponFormik.handleChange}
                  onBlur={addEditCouponFormik.handleBlur}
                  // disabled={isDisabled}
                  value={addEditCouponFormik.values.title}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className={styles.fromGroup}>
                <label>
                  Coupon Code
                  {
                    addEditCouponFormik.touched.couponCode && addEditCouponFormik.errors.couponCode ?
                      <span className="error">{addEditCouponFormik.errors.couponCode}</span>
                      :
                      null
                  }
                </label>
                <input
                  className={styles.input}
                  type="text"
                  placeholder="Coupon Code"
                  name="couponCode"
                  onChange={addEditCouponFormik.handleChange}
                  onBlur={addEditCouponFormik.handleBlur}
                  disabled={isDisabled}
                  value={addEditCouponFormik.values.couponCode}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className={styles.fromGroup}>
                <label>
                  Discount Type
                  {
                    addEditCouponFormik.touched.type && addEditCouponFormik.errors.type ?
                      <span className="error">{addEditCouponFormik.errors.type}</span>
                      :
                      null
                  }
                </label>
                <select
                  disabled={isDisabled}
                  name="type"
                  value={addEditCouponFormik.values.type}
                  onChange={(event) => {

                    addEditCouponFormik.setFieldValue(event.target.name, parseInt(event.target.value));

                    if (parseInt(event.target.value) === 1) {
                      addEditCouponFormik.setFieldValue("regions", []);
                    } else addEditCouponFormik.setFieldValue("countries", []);

                  }}
                >
                  <option value={0}>Select</option>
                  <option value={1}>Countries Specific</option>
                  <option value={2}>Region Specific</option>
                </select>
              </div>
            </div>
            <div className={addEditCouponFormik.values.type === 0 ? "d-none" : "col-md-6"}>
              {addEditCouponFormik.values.type === COUPON_TYPE.COUNTRIES ?
                <div className={styles.fromGroup}>
                  <label>
                    Countries
                    {
                      addEditCouponFormik.touched.countries && addEditCouponFormik.errors.countries ?
                        <span className="error">{addEditCouponFormik.errors.countries}</span>
                        :
                        null
                    }
                  </label>
                  <Select
                    options={countries as any}
                    isMulti
                    onChange={(values) => {
                      addEditCouponFormik.setFieldValue("countries", values);
                      addEditCouponFormik.setFieldValue("regions", []);
                    }}
                    ref={selectCountryInputRef}
                    value={addEditCouponFormik.values.countries}
                    isDisabled={isDisabled}
                    isClearable
                  />
                </div> :
                <div className={styles.fromGroup}>
                  <label>
                    Region
                    {
                      addEditCouponFormik.touched.regions && addEditCouponFormik.errors.regions ?
                        <span className="error">{addEditCouponFormik.errors.regions}</span>
                        :
                        null
                    }
                  </label>
                  <Select
                    options={regions as any}
                    isMulti
                    onChange={(values) => {
                      addEditCouponFormik.setFieldValue("regions", values);
                      addEditCouponFormik.setFieldValue("countries", []);
                    }}
                    ref={selectRegionInputRef}
                    value={addEditCouponFormik.values.regions}
                    isDisabled={isDisabled}
                    isClearable
                  />
                </div>
              }
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className={styles.fromGroup}>
                <div className="d-flex">
                  <label>
                    Discount (%)
                    {
                      addEditCouponFormik.touched.discount && addEditCouponFormik.errors.discount ?
                        <span className="error">{addEditCouponFormik.errors.discount}</span>
                        :
                        null
                    }
                  </label>
                  <div data-tooltip-id="discount" data-tooltip-content="Coupon discount in %">
                    <FontAwesomeIcon
                      size="sm"
                      color="white"
                      className="me-2 cursor-pointer"
                      icon={faInfoCircle}
                    />
                  </div>
                  <ReactTooltip id="discount" />
                </div>
                <input
                  className={styles.input}
                  type="text"
                  placeholder="Discount"
                  name="discount"
                  disabled={isDisabled}
                  onChange={addEditCouponFormik.handleChange}
                  onBlur={addEditCouponFormik.handleBlur}
                  value={addEditCouponFormik.values.discount}
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className={styles.fromGroup}>
                <div className="d-flex">
                  <label>
                    Minimum Spend Amount
                    {
                      addEditCouponFormik.touched.minimumSpendAmount && addEditCouponFormik.errors.minimumSpendAmount ?
                        <span className="error">{addEditCouponFormik.errors.minimumSpendAmount}</span>
                        :
                        null
                    }
                  </label>
                  <div data-tooltip-id="minimumSpendAmount" data-tooltip-content="Minimum purchase amount required to redeem this coupon">
                    <FontAwesomeIcon
                      size="sm"
                      color="white"
                      className="me-2 cursor-pointer"
                      icon={faInfoCircle}
                    />
                  </div>
                  <ReactTooltip id="minimumSpendAmount" />
                </div>
                <input
                  className={styles.input}
                  disabled={isDisabled}
                  type="number"
                  placeholder="Amount"
                  name="minimumSpendAmount"
                  onChange={addEditCouponFormik.handleChange}
                  onBlur={addEditCouponFormik.handleBlur}
                  value={addEditCouponFormik.values.minimumSpendAmount}
                />

              </div>
            </div>

            {/* <div className="col-md-6">
              <div className={styles.fromGroup}>
                <label>
                  Discount Currency
                  {
                    addEditCouponFormik.touched.discountCurrency && addEditCouponFormik.errors.discountCurrency ?
                      <span className="error">{addEditCouponFormik.errors.discountCurrency}</span>
                      :
                      null
                  }
                </label>
                <select
                  disabled={isDisabled}
                  name="discountCurrency"
                  value={addEditCouponFormik.values.discountCurrency}
                  onChange={addEditCouponFormik.handleChange}
                >
                  <option value=''>Select</option>
                  {CurrencyCodes.codes().map((item, index) => (
                    <option key={index} value={item}>{item}</option>
                  ))}
                </select>
              </div>

            </div> */}
          </div>

          <div className="row">

            <div className="col-md-6">
              <div className={styles.fromGroup}>
                <label>
                  Minimum Spend Currency
                  {
                    addEditCouponFormik.touched.minimumSpendCurrency && addEditCouponFormik.errors.minimumSpendCurrency ?
                      <span className="error">{addEditCouponFormik.errors.minimumSpendCurrency}</span>
                      :
                      null
                  }
                </label>
                <select
                  disabled={isDisabled}
                  name="minimumSpendCurrency"
                  value={addEditCouponFormik.values.minimumSpendCurrency}
                  onChange={addEditCouponFormik.handleChange}
                >
                  <option value=''>Select</option>
                  {CurrencyCodes.codes().map((item, index) => (
                    <option key={index} value={item}>{item}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-md-6">
              <div className={styles.fromGroup}>
                <div className="d-flex">
                  <label>
                    Frequency per user
                    {
                      addEditCouponFormik.touched.frequencyPerUser && addEditCouponFormik.errors.frequencyPerUser ?
                        <span className="error">{addEditCouponFormik.errors.frequencyPerUser}</span>
                        :
                        null
                    }
                  </label>
                  <div data-tooltip-id="frequencyPerUser" data-tooltip-content="Maximum redemption limit for this coupon per user">
                    <FontAwesomeIcon
                      size="sm"
                      color="white"
                      className="me-2 cursor-pointer"
                      icon={faInfoCircle}
                    />
                  </div>
                  <ReactTooltip id="frequencyPerUser" />
                </div>

                {/* <p className={styles.subLabel}>How much time can a single user use this voucher for?</p> */}
                <input
                  className={styles.input}
                  disabled={isDisabled}
                  type="number"
                  placeholder="Frequency per user"
                  name="frequencyPerUser"
                  onChange={addEditCouponFormik.handleChange}
                  onBlur={addEditCouponFormik.handleBlur}
                  value={addEditCouponFormik.values.frequencyPerUser}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className={styles.fromGroup}>
                <div className="d-flex">
                  <label>
                    Total Esims
                    {
                      addEditCouponFormik.touched.esims && addEditCouponFormik.errors.esims ?
                        <span className="error">{addEditCouponFormik.errors.esims}</span>
                        :
                        null
                    }
                  </label>
                  <div data-tooltip-id="totalEsims" data-tooltip-content="Minimum number of eSIMs required for coupon eligibility">
                    <FontAwesomeIcon
                      size="sm"
                      color="white"
                      className="me-2 cursor-pointer"
                      icon={faInfoCircle}
                    />
                  </div>
                  <ReactTooltip id="totalEsims" />
                </div>
                {/* <p className={styles.subLabel}>Minimum amount required to avail this voucher</p> */}
                <input
                  className={styles.input}
                  disabled={isDisabled}
                  type="number"
                  placeholder="Esims"
                  name="esims"
                  onChange={addEditCouponFormik.handleChange}
                  onBlur={addEditCouponFormik.handleBlur}
                  value={addEditCouponFormik.values.esims}
                />

              </div>
            </div>

            <div className="col-md-6">
              <div className={styles.fromGroup}>
                <label>
                  Start Date
                  {
                    addEditCouponFormik.touched.startDate && addEditCouponFormik.errors.startDate ?
                      <span className="error">{addEditCouponFormik.errors.startDate}</span>
                      :
                      null
                  }
                </label>
                <DatePicker
                  disabled={isDisabled}
                  name="startDate"
                  className={styles.input}
                  onChange={(date) => addEditCouponFormik.setFieldValue("startDate", date)}
                  selected={new Date(addEditCouponFormik.values.startDate)}
                  minDate={new Date()}
                  dateFormat="dd-MMM-yyyy"
                />

              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className={styles.fromGroup}>
                <label>
                  End Date
                  {
                    addEditCouponFormik.touched.endDate && addEditCouponFormik.errors.endDate ?
                      <span className="error">{addEditCouponFormik.errors.endDate}</span>
                      :
                      null
                  }
                </label>
                <DatePicker
                  disabled={isDisabled}
                  name="endDate"
                  className={styles.input}
                  onChange={(date) => addEditCouponFormik.setFieldValue("endDate", date)}
                  selected={new Date(addEditCouponFormik.values.endDate)}
                  minDate={new Date()}
                  dateFormat="dd-MMM-yyyy"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className={styles.fromGroup}>
                <label>
                  Background Color
                  {
                    addEditCouponFormik.touched.color && addEditCouponFormik.errors.color ?
                      <span className="error">{addEditCouponFormik.errors.color}</span>
                      :
                      null
                  }
                </label>
                {/* <p className={styles.subLabel}>Minimum amount required to avail this voucher</p> */}
                <input
                  className={`${styles.input} ${styles.colorcode}`}
                  disabled={isDisabled}
                  type="color"
                  name="color"
                  onChange={addEditCouponFormik.handleChange}
                  onBlur={addEditCouponFormik.handleBlur}
                  value={addEditCouponFormik.values.color}
                />

              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className={styles.fromGroup}>
                <label>
                  Text Color
                  {
                    addEditCouponFormik.touched.textColor && addEditCouponFormik.errors.textColor ?
                      <span className="error">{addEditCouponFormik.errors.textColor}</span>
                      :
                      null
                  }
                </label>
                {/* <p className={styles.subLabel}>Minimum amount required to avail this voucher</p> */}
                <input
                  className={`${styles.input} ${styles.colorcode} `}
                  disabled={isDisabled}
                  type="color"
                  placeholder="Esims"
                  name="textColor"
                  onChange={addEditCouponFormik.handleChange}
                  onBlur={addEditCouponFormik.handleBlur}
                  value={addEditCouponFormik.values.textColor}
                />

              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className={styles.fromGroup}>
              <input
                type="button"
                disabled={isDisabled}
                onClick={addEditCouponFormik.submitForm}
                className={styles.submit} value={params.id ? "Update" : "Add"}
              />
            </div>
          </div>
        </form>
      </div >
    </Layout >
  );
};

export default ManageCoupon;

