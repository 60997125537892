import { useParams } from 'react-router-dom';
import styles from '../styles/style.module.css';
import { Layout } from '../../../ui';
import { useEffect, useState } from 'react';
import { getDocumentsData } from '../api';
import { toast } from 'react-toastify';



function Document() {
    const params = useParams();
    const [getSingleOrder, setGetSingleOrder] = useState<any>()

    useEffect(()=>{

        getDocumentsData(params?.id).then((res)=>{
            setGetSingleOrder(res?.data)
        }).catch((error)=> {
            toast.error(error?.data?.message || "Something went wrong!")
        })
    }, [])

    return (
        <Layout title="Document">
            {/* {loader && <MainLoader />} */}
            <div className={styles.uploadDoc}>
                <div className='container'>
                    <h3>Upload Documents</h3>
                    <div className={styles.outerUpoad}>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className={styles.innerUpload}>
                                        <h5>Passport</h5>
                                        <h6>Upload Passport Images </h6>
                                        <p>Upload a clear picture of your Passport</p>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className={styles.uploadFrame}>
                                                    {
                                                       
                                                            <img 
                                                            src={getSingleOrder?.documents?.passportFront} 
                                                            alt="passport_front" />
                                                           
                                                    }

                                                </div>
                                                <span className={styles.tagName}>Front</span>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className={styles.uploadFrame}>
                                                    {
                                                            <img 
                                                            src={getSingleOrder?.documents?.passportBack}
                                                             alt='passport_back' />
                                                    }

                                                </div>
                                                <span className={styles.tagName}>Back</span>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className={styles.innerUpload}>
                                        <h5>Visa/Ticket</h5>
                                        <h6>Upload VISA/TICKET Images  </h6>
                                        <p>Upload a clear picture of VISA/TICKET</p>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className={styles.uploadFrame}>
                                                    {
                                                            <img 
                                                            src={getSingleOrder?.documents?.visa}
                                                             alt='visa' />
                                                    }

                                                    {/* <img src={blogone} alt=""/> */}
                                                </div>
                                                <span className={styles.tagName}>Front</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Document