import { UserTypes } from "../../../interfaces";
import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../lib/constants";


export const list = (params: UserTypes.UserListQuery): Promise<UserTypes.UserListResponse> => {
	return axios.get(
		API_ROUTES.USERS.LIST,
		{ params }
	);
}

export const update = (paylaod: UserTypes.UserUpdatePayload): Promise<UserTypes.UserListResponse> => {
	return axios.patch(
		API_ROUTES.USERS.LIST,
		paylaod
	);
}


export const specificCMS = (type: number): Promise<any> => {
	return axios.get(
		"/cms",
		{ params: { type } }
	);
}