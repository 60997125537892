import { CommonTypes, PromoTypes } from "../../../interfaces";
import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../lib/constants";

export const getPromos = (query: PromoTypes.PromosListQuery): Promise<PromoTypes.PromosListResponse> => {
	return axios.get(API_ROUTES.PROMOS.LIST, { params: query });
}

export const specificPromo = (id: string): Promise<PromoTypes.SpecificPromoResponse> => {
	return axios.get(`${API_ROUTES.PROMOS.LIST}/${id}`);
}

export const createPromo = (payload: PromoTypes.NewPromoPayload): Promise<CommonTypes.DefaultResponse> => {
	return axios.post(API_ROUTES.PROMOS.LIST, payload);
}

export const updatePromo = (payload: PromoTypes.UpdatePromoPayload, promoId: string): Promise<CommonTypes.DefaultResponse> => {
	return axios.patch(`${API_ROUTES.PROMOS.UPDATE}/${promoId}`, payload);
}

export const removePromo = (id: string): Promise<PromoTypes.PromosListResponse> => {
	return axios.delete(`${API_ROUTES.PROMOS.LIST}/${id}`);
}

export const listCountries = (params: PromoTypes.ListCountriesQuery): Promise<PromoTypes.CountryListResponse> => {
	return axios.get(API_ROUTES.COUNTRIES.LIST, { params });
}

export const listRegions = (): Promise<PromoTypes.RegionListResponse> => {
	return axios.get(API_ROUTES.REGIONS.LIST);
}