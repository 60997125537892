import { PlanTypes, CommonTypes } from "../../../interfaces";
import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../lib/constants";

export const partnerWebsitesList = (): Promise<any> => {
	return axios.get(`/partner-websites`);
};
export const list = (params: any): Promise<any> => {
	return axios.get(`/partner-websites/users`,
		{ params }
	);
}

export const userOrderlist = (params: any): Promise<any> => {
	return axios.get(`/order/partnerUser`,
		{ params }
	);
}

export const generateVfsLocationQr = (params: any): Promise<any> => {
	return axios.post(`/vfs/employee`, params);
};

export const VfsLocationList = (params: any): Promise<any> => {
	return axios.get(`/vfs/employee/list`, { params });
};
export const VfsCountryList = (params: any): Promise<any> => {
	return axios.get(`/vfs/employee/groupCountry`, { params });
};
export const VfsGroupFinalNameList = (params: any): Promise<any> => {
	return axios.get(`/vfs/employee/groupFinalName`, { params });
};

export const partnerAdminList = (params: any): Promise<any> => {
	return axios.get(`/collaborators`, { params });
};

export const ordersList = (params: any): Promise<any> => {
	return axios.get(API_ROUTES.PAYMENT.PARTNERS_LIST, { params });
};

export const PartnerAdminCreate = (params: any): Promise<any> => {
	return axios.post(`auth/invite/collaborators`, params);
};

export const physicalEsim = (params: any): Promise<any> => {
	return axios.get(`/vfs/physicalEsim`, { params });
};

export const addUpsellForm = (params: any): Promise<any> => {
	const {planName, upsellDate, upsellAmount, upsellAmountCurrency, orderId} = params;
	return axios.post(`order/partner-websites/upsell?planName=${planName}&upsellAmount=${upsellAmount}&upsellAmountCurrency=${upsellAmountCurrency}&upsellDate=${upsellDate}&orderId=${orderId}`);
};

export const niyoUpsellSingleRecord = (params: any): Promise<any> => {
	return axios.get(`order/partner-websites/upsell`, { params });
};

export const niyoUpsellRecordsList = (params: any): Promise<any> => {
	return axios.get(`order/partner-websites/upsell/list`, { params });
};

export const getConfigrations = (): Promise<any> => {
	return axios.get(`/configuration`);
};
export const putConfigrations = (params: any): Promise<any> => {
	return axios.put(`/configuration`, params );
};

export const manuallySentMail = (params: any): Promise<any> => {
	return axios.post(`/order/free/esim/manually`, params );
};

export const getPartnerEsimBundles = (params:any): Promise<any> => {
	return axios.get(`/admin/partnerWebsites/bundle`, {params});
};

export const activePartnerEsimBundles = (params:any): Promise<any> => {
	return axios.put(`/admin/partnerWebsites/bundle`, params);
};

