import * as Yup from "yup";
import moment from "moment";

const CouponSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  couponCode: Yup.string().required("Required"),
  discount: Yup
    .number().required("Required")
    .min(1, "Discount cannot be zero"),
  esims: Yup
    .number().required("Required")
    .min(1, "Total esims cannot be zero"),
  startDate: Yup.string().required("Required"),
  endDate: Yup.string().required("Required").test("voucher-date", function (endDate) {
    let { startDate } = this.parent;

    if (startDate && endDate) {
      const startDateMoment = moment(startDate);
      const endDateMoment = moment(endDate);

      if (!endDateMoment.isAfter(startDateMoment)) {
        throw new Yup.ValidationError(
          "Promo end date cannot be less than start date",
          endDate,
          "endDate"
        );
      }
    }

    return true;
  }),
  minimumSpendAmount: Yup
    .number()
    .required("Required")
    .min(1, "Amount cannot be zero"),
  frequencyPerUser: Yup.number().required("Required"),
  type: Yup.number().required("Required").min(1, "Select discount type"),
  // discountCurrency: Yup.string().test("discount-currency", "Select discount currency", function (value) {
  //   const discountType = this.parent.type;

  //   if (discountType === 2) {
  //     return true;
  //   } else if (!value) {
  //     throw new Yup.ValidationError(
  //       "Select discount currency",
  //       value,
  //       "discountCurrency"
  //     );
  //   }

  //   return true;
  // }),
  minimumSpendCurrency: Yup.string().required("Select minimum spend currency"),
  countries: Yup.array().test("countries", function (values) {
    if (this.parent.type === 1 && values && values.length === 0) { 
      throw new Yup.ValidationError(
        "Required",
        values,
        "countries"
      );
    }

    return true;
  }),

  regions: Yup.array().test("regions", function (values) {
    if (this.parent.type === 2 && values && values.length === 0) { 
      throw new Yup.ValidationError(
        "Required",
        values,
        "regions"
      );
    }

    return true;
  }),
  color: Yup.string().required("Required"),
  textColor: Yup.string().required("Required"),

});

export default CouponSchema;