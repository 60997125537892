import * as Yup from 'yup';
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';

const validationSchema = Yup.object().shape({
  missionServed: Yup.string()
    .required('Mission served is required')
    .matches(/^[A-Za-z\s]+$/, 'Mission served contains only alphabetic characters')
    .max(25)
    .test('no-whitespace', 'Mission served should not start or end with whitespace', value => {
      if (value) {
        return value === value.trim();
      }
      return true;
    }),
    product: Yup.string().required('Product is required'),
    cityOfOperation: Yup.string().required('City Of Operation is required'),
    sharingSchema: Yup.string().required('Sharing Schema is required'),
    combinedFinalName: Yup.string().required('Combined final name is required')
});


const createPartnerAdmin = Yup.object().shape({
  email: Yup.string().required('email is required').email(),
  password: Yup.string().required("Password is required field!").min(8),
});

export {
  validationSchema,
  createPartnerAdmin
};
