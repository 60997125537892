import { CommonTypes, PromoTypes } from "../../../interfaces";
import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../lib/constants";

export const addSeoCountry = (payload: any): Promise<any> => {
	return axios.put(`/seotemplates`, payload);
}

export const getSeoCountryDetails = (id: string): Promise<any> => {
	return axios.get(`seotemplates?countryId=${id}`);
}
