import { useEffect, useState } from "react";
import styles from "../styles/style.module.css";
import commonStyles from "../../../lib/common/common.module.css";
import { LOCAL_CONSTANTS } from "../../../lib/constants";
import { partnerAdminList } from "../api";
import Layout from "../../../ui/layout";
import { Pagination } from "../../../ui";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IMAGES } from "../../../assets/images";
import { toast } from "react-toastify";

const PartnerAdminList = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const { partnerName, partnerWebsiteId } = useParams();
    const navigate = useNavigate();
    const [pagination, setPagination] = useState({
        skip: 0,
        limit: LOCAL_CONSTANTS.limitCount,
    });
    const [activeTab, setActiveTab] = useState(1)
    const [niyoTabbing, setNiyoTabbing] = useState(0)

    const [activeData, setActiveData] = useState<any>([]);

    const [loading, setLoading] = useState<boolean>(true);

    const roles = window.localStorage.getItem("admin_roles")
    const routAccess = (roles !== null && LOCAL_CONSTANTS.ROLES.NIYO_USER === Number(roles)) || (roles !== null && LOCAL_CONSTANTS.ROLES.VFS_USER === Number(roles))

    useEffect(() => {
        setLoading(true);
        const query: any = {
            partnerWebsiteId: partnerWebsiteId,
            page: (1 + pagination.skip),
            limit: pagination.limit
        }
        partnerAdminList(query).then((res: any) => {
            setLoading(false);
            setActiveData(res?.data)
        }).catch((error)=>{
            setLoading(false);
            toast.error(error?.data?.message || "Something went wrong!")
        })
    }, [pagination, activeTab, niyoTabbing]);

    return (
        <Layout title={`${(partnerName)?.toUpperCase()} Admin List`}>
            <div className={commonStyles.tableData}>
                {!routAccess && <div className={styles.mainTabbing}>                   
                    <div className="rightTabbing">
                    <button className={styles.loyalityPointBtn} onClick={() => navigate(`/partners/${partnerName}/${partnerWebsiteId}/create-partner-admin`)}>Create Admin</button>
                    </div>
                </div>}
                

                <div className={styles.scrollTable}>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Password</th>
                                <th>Phone Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading && (
                                <tr>
                                    <td colSpan={7} align="center" className={styles.loadingText}>
                                        {LOCAL_CONSTANTS.loading}
                                    </td>
                                </tr>
                            )}
                            {!loading && activeData?.length == 0 && (
                                <tr>
                                    <td colSpan={7} align="center">
                                        No Admin Found
                                    </td>
                                </tr>
                            )}
                            {!loading &&
                                activeData.map((item: any) => (
                                    <tr key={item._id}>
                                        <td>
                                            <img
                                                width={10}
                                                height={10}
                                                src={
                                                    item.avatar && item.avatar.length
                                                        ? item.avatar
                                                        : IMAGES.RegisteredUserImage
                                                }
                                                alt="userImage"
                                            />
                                             {(partnerName)?.toUpperCase() || LOCAL_CONSTANTS.na}
                                        </td>
                                        <td>{item.email || LOCAL_CONSTANTS.na}</td>
                                        <td>{item.password || LOCAL_CONSTANTS.na}</td>
                                        <td>{item.countryCode} {item.phoneNumber || LOCAL_CONSTANTS.na}</td>                                        
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>

                <Pagination
                    paginationState={pagination}
                    setPaginationState={setPagination}
                    count={activeData.count}
                />
            </div>
        </Layout>
    );
};

export default PartnerAdminList;
