import { UserTypes } from "../../../interfaces";
import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../lib/constants";

export const getProfile = (): Promise<UserTypes.AdminProfileResponse> => {
	return axios.get(API_ROUTES.PROFILE.SPECIFIC);
}

export const updateProfile = (payload: UserTypes.UpdateAdminPayload): Promise<UserTypes.UpdateAdminResponse> => {
	return axios.put(API_ROUTES.PROFILE.UPDATE, payload);
}