import styles from "./style.module.css";
import { setSidebar } from "../../redux/features/sidebarSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { IMAGES } from "../../assets/images";

const Topbar = ({ title }: { title: string }) => {
	const dispatch = useAppDispatch();
	const { sidebar } = useAppSelector((state: RootState) => state.sidebarReducer);

	const toggleSidebar = () => {
		dispatch(setSidebar({ status: !sidebar }));
	};

	return (
		<div className={styles["top-bar"]}>
			<div className="row">
				<div className="col-md-5 col-3">
					<div className={styles.topBarLeft}>
						<span onClick={toggleSidebar} className={styles.Toggle}>
							<i className="fas fa-bars"></i>
						</span>
						<h3>{title}</h3>
						{/* <span>Updated on 22 Feb 2024</span> */}
					</div>
				</div>
				<div className="col-md-7 col-9">
					<div className={styles.topBarRight}>
						{/* <div className={styles["form-group"]}>
							<span>
								<img width={15} height={15} src={IMAGES.SearchIcon} alt="search-icon" />
							</span>
							<input type="text" placeholder="Search" />
						</div> */}
						{/* <div className={styles.notification}>
							<span>2</span>
							<Image width={15} height={15} src={IMAGES.NOTIFICATION_ICON} alt="notification" />
						</div> */}
						<div className={styles.userSelect}>
							<span>
								<img width={33} height={33} src={IMAGES.DefaultUser} alt="user" />
							</span>
							<label>Admin</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Topbar;