import { toast } from "react-toastify";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

import styles from "../styles/planSettings.module.css";
import { tabList, updatePopularCountries, updateTabList } from "../api";
import { localPlanSettingsSchema, multiversePlanSettingsSchema } from "../validations";
import { listCountries } from "../../promos/api";
import { Toggle, PopularCountries } from "../../../components";
import { PlanTypes } from "../../../interfaces";
import { CONSTANTS } from "../../../lib/constants/messages";
import { Layout } from "../../../ui";

const PlanSettings = () => {
  const [homeTabs, setHomeTabs] = useState<PlanTypes.HomeTab[]>([]);
  const [countries, setCountries] = useState<{ value: string, label: string, flag: string, positionNumber?: number }[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<{ value: string, label: string, flag: string }[]>([]);

  const updateHomeTab = (value: string, tabId: string, isDefault: boolean) => {
    let payload: any = {};
    if (value) {
      payload.name = value;
      payload.isDefault = isDefault;
    }

    toast.promise(
      updateTabList(tabId, payload),
      {
        pending: {
          render() {
            return value ? CONSTANTS.HOME_TAB.UPDATING_NAME : CONSTANTS.HOME_TAB.UPDATING_DEFAULT;
          }
        },
        success: {
          render() {
            return value ? CONSTANTS.HOME_TAB.UPDATE_NAME_SUCCESS : CONSTANTS.HOME_TAB.UPDATE_DEFAULT_SUCCESS;
          }
        },
        error: {
          render() {
            return value ? CONSTANTS.HOME_TAB.UPDATE_NAME_FAILED : CONSTANTS.HOME_TAB.UPDATE_DEFAULT_FAILED;
          }
        },
      }
    )
  };

  const localTabFormik = useFormik({
    onSubmit: (values) => updateHomeTab(values.local, values.tabId, values.isDefault),
    initialValues: {
      tabId: "",
      local: "",
      isDefault: false,
    },
    validationSchema: localPlanSettingsSchema,
  });

  const multiVerseFormik = useFormik({
    onSubmit: (values) => updateHomeTab(values.multiverse, values.tabId, values.isDefault),
    initialValues: {
      multiverse: "",
      tabId: "",
      isDefault: false,
    },
    validationSchema: multiversePlanSettingsSchema,
  });

  useEffect(() => {
    tabList().then(res => {
      setHomeTabs(res.data);
    });
  }, []);

  const handleIsDefaultChange = (localIsDefault?: boolean | null, multiverseIsDefault?: boolean | null) => {
    if (typeof localIsDefault === "boolean") {
      localTabFormik.setFieldValue("isDefault", localIsDefault);
      multiVerseFormik.setFieldValue("isDefault", !localIsDefault);
    }
    else {
      localTabFormik.setFieldValue("isDefault", !multiverseIsDefault);
      multiVerseFormik.setFieldValue("isDefault", multiverseIsDefault);
    };
  };

  const updateCountries = () => {
    if(!selectedCountries.length) {
      toast.error("Select atleast one country");
      return;
    }
    const payload = {
      countries: selectedCountries.map((item, index) => ({ _id: item.value, positionNumber: index + 1 }))
    };

    toast.promise(
      updatePopularCountries(payload),
      {
        pending: {
          render() {
            return CONSTANTS.POPULAR_COUNTRIES.UPDATING;
          }
        },
        success: {
          render() {
            return CONSTANTS.POPULAR_COUNTRIES.UPDATE_POPULAR_COUNTRIES_SUCCESS;
          }
        },
        error: {
          render() {
            return CONSTANTS.POPULAR_COUNTRIES.UPDATE_POPULAR_COUNTRIES_FAILED;
          }
        },
      }
    )
  };

  const moveCountry = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragItem = selectedCountries[dragIndex]
      const hoverItem = selectedCountries[hoverIndex]

      setSelectedCountries(countries => {
        const updatedCoutries = [...countries];
        updatedCoutries[dragIndex] = hoverItem;
        updatedCoutries[hoverIndex] = dragItem;

        return updatedCoutries;
      })
    },
    [selectedCountries],
  )

  useEffect(() => {
    if (homeTabs.length) {

      for (const item of homeTabs) {
        if (item.type === 1) localTabFormik.setValues({ local: item.name, tabId: item._id, isDefault: item.isDefault });
        else if (item.type === 2) multiVerseFormik.setValues({ multiverse: item.name, tabId: item._id, isDefault: item.isDefault });
      }
    }
  }, [homeTabs]);

  useEffect(() => {
    listCountries({ limit: 1000 }).then(res => {
      const countries = res.data.countries
        .filter(item => item.flagImageUrl && item.historicImageUrl)
        .map(item => ({ label: item.name, value: item._id, flag: item.flagImageUrl, positionNumber: item.positionNumber }));

      const selectedCountries = countries.filter(item => item.positionNumber);
      setSelectedCountries(selectedCountries);
      
      setCountries(countries);
    });
  }, []);

  return (
    <Layout title="Plan Settings">
      <div className={styles.settings}>
        <p className="text-white">Home Tabs</p>

        <div className="row align-items-end">
          <div className="col-md-10">
            <label className="text-white">Name</label>
            <input
              className={styles.input}
              value={localTabFormik.values.local}
              onChange={localTabFormik.handleChange}
              onBlur={localTabFormik.handleBlur}
              name="local"
            />
            {
              localTabFormik.touched.local && localTabFormik.errors.local ?
                <span className="error">{localTabFormik.errors.local}</span>
                :
                null
            }
          </div>

          <div className="col-md-2">
            <div className={styles.settingTogles}>
              <Toggle value={localTabFormik.values.isDefault} handleChange={() => handleIsDefaultChange(!localTabFormik.values.isDefault, null)} />
              <button className={styles.submit} onClick={localTabFormik.submitForm}>Update</button>
            </div>
          </div>

        </div>
        <div className="row align-items-end mt-4">
          <div className="col-md-10">
            <label className="text-white">Name</label>
            <input
              className={styles.input}
              value={multiVerseFormik.values.multiverse}
              onChange={multiVerseFormik.handleChange}
              onBlur={multiVerseFormik.handleBlur}
              name="multiverse"
            />
            {
              multiVerseFormik.touched.multiverse && multiVerseFormik.errors.multiverse ?
                <span className="error">{multiVerseFormik.errors.multiverse}</span>
                :
                null
            }
          </div>
          <div className="col-md-2">
            <div className={styles.settingTogles}>
              <Toggle value={multiVerseFormik.values.isDefault} handleChange={() => handleIsDefaultChange(null, !multiVerseFormik.values.isDefault)} />
              <button className={styles.submit} onClick={multiVerseFormik.submitForm}>Update</button>
            </div>
          </div>


        </div>
      </div>

      <div className={styles.settings}>
        <p className="text-white">Popular Countries</p>
        <Select
          options={countries as any}
          isMulti
          onChange={(values: any) => {
            if(values.length <= 6) {
              setSelectedCountries(values);
            }
            else {
              toast.error("You cannot select more than 6 countries");
            };
          }}
          value={selectedCountries}
          isClearable
        />

        {
          selectedCountries.length &&
          <Fragment>
            <DndProvider backend={HTML5Backend}>
              <div className="row">
                {selectedCountries.map((item, index) => (
                  <PopularCountries {...item} index={index} key={index} moveCountry={moveCountry} />
                ))}
              </div>
            </DndProvider>
            <div className="d-flex justify-content-end mt-3">
              <button className={styles.submit} onClick={updateCountries}>Update</button>
            </div>
          </Fragment>
        }
      </div>
    </Layout>
  );
};

export default PlanSettings;