import { useState } from 'react';
import { Layout } from "../../../ui";
import styles from "../styles/form.module.css";
import { useLocation, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { validationSchema } from '../validations';
import { toast } from 'react-toastify';
import { generateVfsLocationQr } from '../api';

function GenerateVsfEmployeeQr() {
    const { id } = useParams();
    const location = useLocation();
    const { partnerWebsiteId } = useParams();
    const [qrCode, setQrCode] = useState("")

    const formik = useFormik({
        initialValues: {
            partnerWebsiteId: "",
            missionServed: "",
            product: "",
            cityOfOperation: "",
            sharingSchema: "",
            combinedFinalName: "",
        },
        enableReinitialize: true, // Allow reinitialization when props change
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const payload: any = {
                partnerWebsiteId: partnerWebsiteId,
                missionServed: values?.missionServed,
                product: values?.product,
                cityOfOperation: values?.cityOfOperation,
                sharingSchema: values?.sharingSchema,
                combinedFinalName: values?.combinedFinalName,
            };
            toast.promise(
                generateVfsLocationQr(payload),
                {
                    pending: {
                        render() {
                            return "Trying to generate QR code...";
                        }
                    },
                    success: {
                        render({ data }: any) {
                            setQrCode(data?.data?.qrCodeUrl)
                            return "QR code successfully generated!";
                        }
                    },
                    error: {
                        render({ data }: any) {
                            return data?.data?.message;
                        }
                    },
                }
            );
        },
    });


    return (
        <div>
            <Layout title={`Generate Qr Code`}>
                <div className={styles.SeoOuter}>
                    <div className='row'>
                        <div className='col-md-8'>
                            <div className={styles.leftSeo}>
                                {/* <h4>SEO Template</h4> */}
                                <form onSubmit={formik.handleSubmit}>                                  
                                    <div className={styles.FormGroup}>
                                        <label>Mission Served</label>
                                        <input
                                            type='text'
                                            placeholder='Please enter employeeId'
                                            name='missionServed'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.missionServed}
                                        />
                                        {formik.touched.missionServed && formik.errors.missionServed && (
                                            <div className={styles.error}>{formik.errors.missionServed}</div>
                                        )}
                                    </div>
                                    <div className={styles.FormGroup}>
                                        <label>Product</label>
                                        <input
                                            type='text'
                                            placeholder='Pleae enter name'
                                            name='product'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.product}
                                        />
                                        {formik.touched.product && formik.errors.product && (
                                            <div className={styles.error}>{formik.errors.product}</div>
                                        )}
                                    </div>
                                    <div className={styles.FormGroup}>
                                        <label>City of Operation</label>
                                        <input
                                            type='text'
                                            placeholder='Please enter employeeId'
                                            name='cityOfOperation'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.cityOfOperation}
                                        />
                                        {formik.touched.cityOfOperation && formik.errors.cityOfOperation && (
                                            <div className={styles.error}>{formik.errors.cityOfOperation}</div>
                                        )}
                                    </div>

                                    <div className={styles.FormGroup}>
                                        <label>Sharing Schema</label>
                                        <input
                                            type='text'
                                            placeholder='Pleae enter name'
                                            name='sharingSchema'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.sharingSchema}
                                        />
                                        {formik.touched.sharingSchema && formik.errors.sharingSchema && (
                                            <div className={styles.error}>{formik.errors.sharingSchema}</div>
                                        )}
                                    </div>
                                    <div className={styles.FormGroup}>
                                        <label>Combined Final Name</label>
                                        <input
                                            type='text'
                                            placeholder='Pleae enter name'
                                            name='combinedFinalName'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.combinedFinalName}
                                        />
                                        {formik.touched.combinedFinalName && formik.errors.combinedFinalName && (
                                            <div className={styles.error}>{formik.errors.combinedFinalName}</div>
                                        )}
                                    </div>

                                    <div className={styles.FormGroup}>
                                        <input type='submit' value="Save" />
                                    </div>
                                </form>
                            </div>
                            {
                                qrCode && (
                                    <div className={styles.download_main}>
                                        <div className={styles.QR_code}>
                                            <img src={qrCode} alt="" />
                                        </div>
                                        <a href={qrCode} className={styles.download_btn} target="_blank" rel="noopener noreferrer">Download QR</a>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    );
}

export default GenerateVsfEmployeeQr;
