import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import CurrencyCodes from "currency-codes";
import DatePicker from "react-datepicker";
import moment from "moment";
import { toast } from "react-toastify";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";

import styles from "../styles/add.module.css";
import { createPromo, listCountries, specificPromo, updatePromo } from "../api";
import PromoSchema from "../validations/promos";
import { APP_ROUTES } from "../../../lib/constants";
import { CONSTANTS } from "../../../lib/constants/messages";
import { Layout } from "../../../ui";

const AddNewPromo = () => {
    const navigate = useNavigate();
    const params = useParams();

    const [countries, setCountries] = useState<{ value: string, label: string }[]>([]);
    const selectInputRef = useRef<any>();
    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    const onClear = () => {
        if (selectInputRef.current) {
            selectInputRef.current.clearValue();
        }
    };

    const addEditPromoFormik = useFormik({
        initialValues: {
            title: "",
            promoCode: "",
            discount: 0,
            startDate: moment().add(1, "day").toString(),
            endDate: moment().add(3, "days").toString(),
            frequencyPerUser: 0,
            minimumSpend: 0,
            minimumSpendCurrency: "",
            discountCurrency: "",
            type: "0",
            countries: [],
        },
        validationSchema: PromoSchema,
        onSubmit: (values, { setSubmitting }) => {
            let payload: any = {
                title: values.title,
                frequencyPerUser: values.frequencyPerUser,
                discount: values.discount,
                minimumSpendAmount: values.minimumSpend,
                minimumSpendCurrency: values.minimumSpendCurrency,
                promoCode: values.promoCode,
                startDate: values.startDate,
                endDate: values.endDate,
                type: parseInt(values.type),
                countries: values.countries.map((item: any) => item.value),
            };

            if(values.discountCurrency.length) {
                payload['discountCurrency'] = values.discountCurrency;
            }

            if (params.id) {
                payload["promoId"] = params.id;
            }

            toast.promise(
                params.id ? updatePromo(payload, params.id as string) : createPromo(payload),
                {
                    pending: {
                        render() {
                            return params.id ? CONSTANTS.PROMO.UPDATING : CONSTANTS.PROMO.CREATING;
                        },
                    },
                    success: {
                        render() {
                            setSubmitting(false);

                            addEditPromoFormik.resetForm();
                            onClear();
                            navigate(APP_ROUTES.PROMOS);

                            return params.id ? CONSTANTS.PROMO.UPDATE_SUCCESS : CONSTANTS.PROMO.CREATE_SUCCESS;
                        },
                    },
                    error: {
                        render({ data }: any) {
                            setSubmitting(false);

                            return params.id ? data.data.message : CONSTANTS.PROMO.CREATE_FAILED;
                        },
                    },
                });
        },
    });

    useEffect(() => {
        listCountries({ limit: 1000 }).then(({ data }) => {
            const countries = data.countries.map(item => ({ label: item.name, value: item._id }))
            setCountries(countries);
        });
    }, []);

    useEffect(() => {
        if (params.id) {
            specificPromo(params.id as string).then(res => {
                const { promo } = res.data;

                if (moment(promo.startDate).isBefore(moment()) && moment(promo.endDate).isAfter(moment())) {
                    setIsDisabled(true);
                    toast.error("You cannot edit this promo since it has already started");
                }
                else if (moment(promo.endDate).isBefore(moment())) {
                    setIsDisabled(true);
                    toast.error("You cannot edit this promo since it has already ended");
                }

                const countries: any = promo.countries.map(item => ({ value: item._id, label: item.name }));

                addEditPromoFormik.setValues({
                    title: promo.title,
                    promoCode: promo.promoCode,
                    discount: promo.discount,
                    countries: countries,
                    type: promo.type.toString(),
                    discountCurrency: promo.discountCurrency,
                    minimumSpendCurrency: promo.minimumSpendCurrency,
                    startDate: promo.startDate,
                    endDate: promo.endDate,
                    frequencyPerUser: promo.frequencyPerUser,
                    minimumSpend: promo.minimumSpend,
                });
            });
        }
    }, [params.id]);

    useEffect(() => {

    }, [countries]);

    return (
        <Layout title="Promos">
            <div className={styles.addCoupon}>
                <h4>{params.id ? "Edit" : "Add new"} promo</h4>
                <form className="mt-3">
                    <div className="row">
                        {/* <div className="col-md-12">
                            <div className={styles.fromGroup}>
                                <label>Add Image</label>
                                <div className={styles.fileTypee}>
                                    <input type="file" placeholder="" />
                                    <div className={styles.fileBtns}>
                                        <Image width={100} height={100} src="/assets/images/fileType.png" alt="userImage" />
                                        <span>Choose File  <Image width={100} height={100} src="/assets/images/phtoo.png" alt="userImage" /></span>
                                        <p>No File Choosen </p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className={styles.fromGroup}>
                                <label>
                                    Title
                                    {
                                        addEditPromoFormik.touched.title && addEditPromoFormik.errors.title ?
                                            <span className="error">{addEditPromoFormik.errors.title}</span>
                                            :
                                            null
                                    }
                                </label>
                                <input
                                    className={styles.input}
                                    type="text"
                                    placeholder="Promo Title"
                                    name="title"
                                    onChange={addEditPromoFormik.handleChange}
                                    onBlur={addEditPromoFormik.handleBlur}
                                    disabled={isDisabled}
                                    value={addEditPromoFormik.values.title}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className={styles.fromGroup}>
                                <label>
                                    Promo Code
                                    {
                                        addEditPromoFormik.touched.promoCode && addEditPromoFormik.errors.promoCode ?
                                            <span className="error">{addEditPromoFormik.errors.promoCode}</span>
                                            :
                                            null
                                    }
                                </label>
                                <input
                                    className={styles.input}
                                    type="text"
                                    placeholder="Promo Code"
                                    name="promoCode"
                                    onChange={addEditPromoFormik.handleChange}
                                    onBlur={addEditPromoFormik.handleBlur}
                                    disabled={isDisabled}
                                    value={addEditPromoFormik.values.promoCode}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className={styles.fromGroup}>
                                <label>
                                    Discount
                                    {
                                        addEditPromoFormik.touched.discount && addEditPromoFormik.errors.discount ?
                                            <span className="error">{addEditPromoFormik.errors.discount}</span>
                                            :
                                            null
                                    }
                                </label>
                                <input
                                    className={styles.input}
                                    type="number"
                                    placeholder="Discount"
                                    name="discount"
                                    disabled={isDisabled}
                                    onChange={addEditPromoFormik.handleChange}
                                    onBlur={addEditPromoFormik.handleBlur}
                                    value={addEditPromoFormik.values.discount}
                                />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className={styles.fromGroup}>
                                <label>
                                    Countries
                                    {
                                        addEditPromoFormik.touched.countries && addEditPromoFormik.errors.countries ?
                                            <span className="error">{addEditPromoFormik.errors.countries}</span>
                                            :
                                            null
                                    }
                                </label>
                                <Select
                                    options={countries as any}
                                    isMulti
                                    onChange={(values) => addEditPromoFormik.setFieldValue("countries", values)}
                                    ref={selectInputRef}
                                    value={addEditPromoFormik.values.countries}
                                    isDisabled={isDisabled}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className={parseInt(addEditPromoFormik.values.type) === 1 ? "col-md-4" : "col-md-6"}>
                            <div className={styles.fromGroup}>
                                <label>
                                    Discount Type
                                    {
                                        addEditPromoFormik.touched.type && addEditPromoFormik.errors.type ?
                                            <span className="error">{addEditPromoFormik.errors.type}</span>
                                            :
                                            null
                                    }
                                </label>
                                <select
                                    disabled={isDisabled}
                                    name="type"
                                    value={addEditPromoFormik.values.type}
                                    onChange={addEditPromoFormik.handleChange}
                                >
                                    <option value={0}>Select</option>
                                    <option value={1}>Fixed</option>
                                    <option value={2}>Percentage</option>
                                </select>
                            </div>
                        </div>
                        {
                            parseInt(addEditPromoFormik.values.type) === 1 ?
                                <div className="col-md-4">
                                    <div className={styles.fromGroup}>
                                        <label>
                                            Discount Currency
                                            {
                                                addEditPromoFormik.touched.discountCurrency && addEditPromoFormik.errors.discountCurrency ?
                                                    <span className="error">{addEditPromoFormik.errors.discountCurrency}</span>
                                                    :
                                                    null
                                            }
                                        </label>
                                        <select
                                            disabled={isDisabled}
                                            name="discountCurrency"
                                            value={addEditPromoFormik.values.discountCurrency}
                                            onChange={addEditPromoFormik.handleChange}
                                        >
                                            <option value=''>Select</option>
                                            {CurrencyCodes.codes().map((item, index) => (
                                                <option key={index} value={item}>{item}</option>
                                            ))}
                                        </select>

                                    </div>
                                </div> : null
                        }
                        <div className={parseInt(addEditPromoFormik.values.type) === 1 ? "col-md-4" : "col-md-6"}>
                            <div className={styles.fromGroup}>
                                <label>
                                    Minimum Spend Currency
                                    {
                                        addEditPromoFormik.touched.minimumSpendCurrency && addEditPromoFormik.errors.minimumSpendCurrency ?
                                            <span className="error">{addEditPromoFormik.errors.minimumSpendCurrency}</span>
                                            :
                                            null
                                    }
                                </label>
                                <select
                                    disabled={isDisabled}
                                    name="minimumSpendCurrency"
                                    value={addEditPromoFormik.values.minimumSpendCurrency}
                                    onChange={addEditPromoFormik.handleChange}
                                >
                                    <option value=''>Select</option>
                                    {CurrencyCodes.codes().map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </select>

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className={styles.fromGroup}>
                                <label>
                                    Start Date
                                    {
                                        addEditPromoFormik.touched.startDate && addEditPromoFormik.errors.startDate ?
                                            <span className="error">{addEditPromoFormik.errors.startDate}</span>
                                            :
                                            null
                                    }
                                </label>
                                <DatePicker
                                    disabled={isDisabled}
                                    name="startDate"
                                    className={styles.input}
                                    onChange={(date) => addEditPromoFormik.setFieldValue("startDate", date)}
                                    selected={new Date(addEditPromoFormik.values.startDate)}
                                    minDate={new Date()}
                                    dateFormat="dd-MMM-yyyy"
                                />

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className={styles.fromGroup}>
                                <label>
                                    End Date
                                    {
                                        addEditPromoFormik.touched.endDate && addEditPromoFormik.errors.endDate ?
                                            <span className="error">{addEditPromoFormik.errors.endDate}</span>
                                            :
                                            null
                                    }
                                </label>
                                <DatePicker
                                    disabled={isDisabled}
                                    name="endDate"
                                    className={styles.input}
                                    onChange={(date) => addEditPromoFormik.setFieldValue("endDate", date)}
                                    selected={new Date(addEditPromoFormik.values.endDate)}
                                    minDate={new Date()}
                                    dateFormat="dd-MMM-yyyy"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className={styles.fromGroup}>
                                <label>
                                    Frequency per user
                                    {
                                        addEditPromoFormik.touched.frequencyPerUser && addEditPromoFormik.errors.frequencyPerUser ?
                                            <span className="error">{addEditPromoFormik.errors.frequencyPerUser}</span>
                                            :
                                            null
                                    }
                                </label>
                                <p className={styles.subLabel}>How much time can a single user use this promo for?</p>
                                <input
                                    className={styles.input}
                                    disabled={isDisabled}
                                    type="number"
                                    placeholder="Frequency per user"
                                    name="frequencyPerUser"
                                    onChange={addEditPromoFormik.handleChange}
                                    onBlur={addEditPromoFormik.handleBlur}
                                    value={addEditPromoFormik.values.frequencyPerUser}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className={styles.fromGroup}>
                                <label>
                                    Minimum Amount
                                    {
                                        addEditPromoFormik.touched.minimumSpend && addEditPromoFormik.errors.minimumSpend ?
                                            <span className="error">{addEditPromoFormik.errors.minimumSpend}</span>
                                            :
                                            null
                                    }
                                </label>
                                <p className={styles.subLabel}>Minimum amount required to avail this promo</p>
                                <input
                                    className={styles.input}
                                    disabled={isDisabled}
                                    type="number"
                                    placeholder="Amount"
                                    name="minimumSpend"
                                    onChange={addEditPromoFormik.handleChange}
                                    onBlur={addEditPromoFormik.handleBlur}
                                    value={addEditPromoFormik.values.minimumSpend}
                                />

                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className={styles.fromGroup}>
                            <input type="button" disabled={isDisabled} onClick={addEditPromoFormik.submitForm} className={styles.submit} value={params.id ? "Update" : "Add"} />
                        </div>
                    </div>
                </form>
            </div>
        </Layout>
    );
};

export default AddNewPromo;
