import * as Yup from "yup";
import { CONSTANTS } from "../../../lib/constants/messages";


const loginSchema = Yup.object().shape({
	email: Yup
		.string()
		.email(CONSTANTS.VALIDATIONS.EMAIL)
		.required(CONSTANTS.VALIDATIONS.REQUIRED),
	password: Yup.string().required(CONSTANTS.VALIDATIONS.REQUIRED)
});

export default loginSchema;