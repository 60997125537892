import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import moment from "moment";
import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import commonStyles from "../../../lib/common/common.module.css";
import { update, listFAQs, removeFAQ } from "../api";
import { CMSSchema } from "../validations";
import styles from "../styles/cms.module.css";
import { LOCAL_CONSTANTS } from "../../../lib/constants";
import { CMSTypes } from "../../../interfaces";
import { CONSTANTS } from "../../../lib/constants/messages";
import { ActionButton, DropDownMenu, Pagination, Popup } from "../../../ui";
import CreateUpdateFAQPopup from "../../../ui/Popup/createUpdateFAQ";
import { IMAGES } from "../../../assets/images";

interface Props {
  cms: CMSTypes.SpecificCMS;
  cmsType: number;
  resetCMS: () => void;
};

const FAQ = ({ cms, cmsType, resetCMS }: Props) => {
  const [faqPopup, setFaqPopup] = useState<{ show: boolean, faq: CMSTypes.Faq | null }>({ show: false, faq: null });
  const [faqLoading, setFAQLoading] = useState<boolean>(true);
  const [faqs, setFaqs] = useState<{ list: CMSTypes.Faq[], count: number }>({ list: [], count: 0 });
  const [pagination, setPagination] = useState<{ skip: number, limit: number }>({
    skip: 0,
    limit: LOCAL_CONSTANTS.limitCount,
  });
  const [dialog, setDialog] = useState({ show: false, id: "" });

  const getFaqs = () => {
    const query = {
      page: (1 + pagination.skip),
      limit: pagination.limit,
    }
    listFAQs(query).then(res => {
      setFaqs({ list: res.data.faqs, count: res.data.count });
      setFAQLoading(false);
    });
  };

  const deleteFAQ = () => {
    toast.promise(
      removeFAQ(dialog.id),
      {
        pending: {
          render() {
            return CONSTANTS.FAQ.REMOVING;
          },
        },
        success: {
          render() {
            getFaqs();

            setDialog({ show: false, id: "" });
            return CONSTANTS.FAQ.REMOVE_SUCCESS;
          },
        },
        error: {
          render({ data }: any) {
            return CONSTANTS.FAQ.REMOVE_FAILED;
          },
        },
      });
  };

  const cmsFormik = useFormik({
    initialValues: { content: cms && cms.content },
    onSubmit: (values, { setSubmitting }) => {
      const payload = { content: values.content as string, type: cmsType };

      toast.promise(
        update(payload),
        {
          pending: {
            render() {
              return CONSTANTS.CMS.UPDATING;
            },
          },
          success: {
            render() {
              resetCMS();

              return CONSTANTS.CMS.SUCCESS;
            },
          },
          error: {
            render() {
              setSubmitting(false);

              return CONSTANTS.CMS.FAILED;
            },
          },
        });
    },
    enableReinitialize: true,
    validationSchema: CMSSchema,
  });

  useEffect(() => {
    setFAQLoading(true);
    getFaqs();
  }, [pagination.skip]);

  return (
    <div>
      <form
        onSubmit={cmsFormik.handleSubmit}
      >
        <div className={styles.tabDtahd}>
          <h4>FAQ</h4>
          <div>
            <button type="button" onClick={() => setFaqPopup({ show: true, faq: null })}>
              <img
                width={100}
                height={100}
                src={IMAGES.EditIcon}
                alt="defaultUser"
              />
              Create
            </button>
          </div>
        </div>

        <div
          className={commonStyles.tableData}
        >
          <div className={commonStyles.tableData}>
            <table>
              <thead>
                <tr>
                  {LOCAL_CONSTANTS.faqTableHeaders?.map((header: any) => (
                    <th
                      className={`${["fullName", "phoneNumber"].includes(header?.value)
                        ? "cursor-pointer"
                        : ""
                        }`}
                      key={header?.value}
                    >
                      {header?.label}

                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {faqLoading && (
                  <tr>
                    <td colSpan={7} align="center" className={styles.loadingText}>
                      {LOCAL_CONSTANTS.loading}
                    </td>
                  </tr>
                )}
                {!faqLoading && faqs.list.length === 0 && (
                  <tr>
                    <td colSpan={3} align="center">
                      No Faq Found
                    </td>
                  </tr>
                )}
                {!faqLoading &&
                  faqs.list.map((item) => (
                    <tr key={item._id}>
                      <td>

                        {item.question}
                      </td>
                      <td>
                        {moment(item.createdAt).format("YYYY-MM-DD")}
                      </td>
                      <td>
                        <Dropdown className="ps-0">
                          <div className="cursor-pointer">
                            <Dropdown.Toggle as={ActionButton}></Dropdown.Toggle>
                          </div>
                          <Dropdown.Menu as={DropDownMenu}>
                            <Dropdown.Item onClick={() => setFaqPopup({ show: true, faq: item })}>
                              <FontAwesomeIcon
                                size="xs"
                                className="me-2"
                                icon={faPencilAlt}
                              />
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => setDialog({ show: true, id: item._id })}>
                              <FontAwesomeIcon
                                size="xs"
                                className="me-2"
                                icon={faTrashAlt}
                              />
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {faqs.count > faqs.list.length &&
              <Pagination
                paginationState={pagination}
                setPaginationState={setPagination}
                count={faqs.count}
              />
            }
          </div>
        </div>
      </form>

      <CreateUpdateFAQPopup
        show={faqPopup.show}
        handleClose={() => setFaqPopup({ show: false, faq: null })}
        getFaqs={getFaqs}
        faq={faqPopup.faq}
      />
      <Popup
        show={dialog.show}
        handleClose={() => setDialog({ show: false, id: "" })}
        heading={LOCAL_CONSTANTS.areYouSure}
        content={LOCAL_CONSTANTS.removeFAQ}
        handleSubmit={deleteFAQ}
        id={dialog.id}
      />
    </div>
  );
};

export default FAQ;