import { OrderTypes } from "../../../interfaces";
import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../lib/constants";


export const list = (
  params: OrderTypes.PaymentListQuery
): Promise<OrderTypes.PaymentListResponse> => {
  return axios.get(API_ROUTES.PAYMENT.LIST, { params });
};

export const partnerWebsitesList = (): Promise<any> => {
  return axios.get(`/partner-websites`);
};

