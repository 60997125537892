import React, { useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";

import styles from "../styles/list.module.css";
import { LOCAL_CONSTANTS } from "../../../lib/constants";
import { NotificationTypes } from "../../../interfaces";
import { IMAGES } from "../../../assets/images";
import { CONSTANTS } from "../../../lib/constants/messages";
import { Layout, Pagination } from "../../../ui";
import { list, remove } from "../api";

function Notification() {
  const [pagination, setPagination] = useState({
    skip: 0,
    limit: LOCAL_CONSTANTS.limitCount,
  });
  const [loading, setLoading] = useState<boolean>(true);

  const [notifications, setNotifications] = useState<{ list: { _id: string, notifications: NotificationTypes.Notifications[] }[]; count: number }>({
    list: [],
    count: 0,
  });

  const listNotifications = (pageIndex: number = 0) => {
    let query = {
      page: (1 + pageIndex),
      limit: pagination.limit,
    };

    list(query).then(res => {
      const { notifications, count } = res.data;

      setNotifications({ list: notifications, count: count });
      setLoading(false);
    });
  };

  const removeNotification = (notificationId: string) => {
    toast.promise(
      remove(notificationId),
      {
        pending: {
          render() {
            return CONSTANTS.NOTIFICATIONS.DELETING;
          },
        },
        success: {
          render() {
            listNotifications();
            return CONSTANTS.NOTIFICATIONS.DELETE_SUCCESS;
          },
        },
        error: {
          render({ data }: any) {
            return CONSTANTS.NOTIFICATIONS.DELETE_FAILED;
          },
        },
      });
  };

  useEffect(() => {
    const debounceSearch = setTimeout(() => {
      listNotifications(pagination.skip);
    }, 500);
    return () => {
      clearTimeout(debounceSearch);
    };
  }, [pagination.skip]);

  if (loading) {
    return <Layout title="Notifications"><p className={styles.loading}>Loading...</p></Layout>
  }

  return (
    <Layout title="Notifications">
      <div className={styles.Notification}>
        <div className={styles.topNotify}>
          {/* <ul>
            <li className={styles.active}><a href="#">All</a></li>
            <li><a href="#">Company</a></li>
            <li><a href="#">Users</a></li>
            <li><a href="#">Push Notification </a></li>
          </ul>
          <button>Manage Notification</button> */}
        </div>
        {notifications.list.map(item => (
          <div key={item._id} className={styles.notificationList}>

            <h6>{moment(item._id, "DD-MM-YYYY").format("MMM DD, YYYY")}</h6>
            <ul>
              {item.notifications.map(it => (
                <li key={it._id}>
                  <span>
                    <img src={IMAGES.AdminNotificationIcon} alt="threeDot" />
                  </span>
                  <div className={styles.NotiContnt}>
                    <h5>{it.title}</h5>
                    <p>{it.description}</p>
                  </div>
                  <label>
                    <img src={IMAGES.TrashIcon} alt="trash" onClick={() => removeNotification(it._id)} />
                  </label>
                </li>
              ))}
            </ul>
          </div>
        ))}
        <Pagination
          paginationState={pagination}
          setPaginationState={setPagination}
          count={notifications.count}
        />
      </div>
    </Layout>
  )
}

export default Notification