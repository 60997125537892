import { createSlice, PayloadAction } from "@reduxjs/toolkit";


const initialState = {
	sidebar: false,
}

export const sidebarSlice = createSlice({
	name: "sidebar",
	initialState,
	reducers: {
		setSidebar: (state, action: PayloadAction<any>) => {
			state.sidebar = action.payload.status;
		}
	}
})

export const {
	setSidebar,
} = sidebarSlice.actions

export default sidebarSlice.reducer