import * as XLSX from 'xlsx';

export const exportToExcelInChunks = (data: any, fileName: any, chunkSize: any, customHeader: any[]) => {
  const totalChunks = Math.ceil(data.length / chunkSize);
  const worksheet = XLSX.utils.json_to_sheet([]);
  const workbook = XLSX.utils.book_new();

  // Add custom header
  XLSX.utils.sheet_add_aoa(worksheet, [customHeader], { origin: 'A1' });

  for (let i = 0; i < totalChunks; i++) {
    const chunk = data.slice(i * chunkSize, (i + 1) * chunkSize);
    XLSX.utils.sheet_add_json(worksheet, chunk, { skipHeader: true, origin: -1 });
  }

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Orders Data');

  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
  const href = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = `${fileName}.xlsx`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
