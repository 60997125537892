import * as Yup from "yup";

const passwordSchema = Yup.object().shape({
	old: Yup.string()
		.required("Old password is required")
		.min(6, "Old password must be at least 6 characters"),

	new: Yup.string()
		.required("New password is required")
		.min(6, "New password must be at least 6 characters"),

	confirm: Yup.string()
		.required("Confirm password is required")
		.oneOf([Yup.ref("new"), ""], "Passwords must match"),
});

export default passwordSchema;