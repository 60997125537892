import React, { forwardRef } from "react";

import styles from "./style.module.css";

type CustomMenuProps = {
    children?: React.ReactNode;
    style?: React.CSSProperties;
    className?: string;
    labeledBy?: string;
};

const DropDownMenu = forwardRef(
	(props: CustomMenuProps, ref: React.Ref<HTMLDivElement>) => {

		return (
			<div
				ref={ref}
				style={props.style}
				className={`${props.className} ${styles.dropDownList}`}
				aria-labelledby={props.labeledBy}
			>
				<ul >
					{props.children}
				</ul>
			</div>
		);
	}
);

export default DropDownMenu;