import { configureStore } from "@reduxjs/toolkit";

import sidebarReducer from "./features/sidebarSlice";

export const store = configureStore({
	reducer: {
		sidebarReducer
	}
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;