import { CommonTypes, LoyalityPointTypes } from "../../../interfaces";
import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../lib/constants";

export const getLoyalityPoints = (): Promise<LoyalityPointTypes.LoyalityPointsResponse> => {
	return axios.get(API_ROUTES.LOYALITY_POINTS.LIST);
}

export const updateLoyalityPoints = (payload: LoyalityPointTypes.UpdatePointPayload): Promise<CommonTypes.DefaultResponse> => {
	return axios.put(API_ROUTES.LOYALITY_POINTS.UPDATE, payload);
}

export const getUserLoyalityPoints = (query: any): Promise<LoyalityPointTypes.UserLoyalityPointsResponse> => {
	return axios.get(API_ROUTES.LOYALITY_POINTS.USER, { params: query });
}

