import * as Yup from "yup";

const BlogsSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  topic: Yup.string().required("Required"),
  country: Yup.array().required("Required").min(1, "Required"),
  image: Yup.string().required("Required"),
  rating: Yup
    .number().required("Required")
    .min(1, "Required"),
});

export default BlogsSchema;