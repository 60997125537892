import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

import styles from "../../features/plans/styles/planSettings.module.css";

interface Props {
  label: string;
  value: string;
  flag: string;
  index: number;
  moveCountry: (dragIndex: number, hoverIndex: number) => void;
};

const PopularCountries = (props: Props) => {
  const ref = useRef<any>(null);

  const [{ isDragging }, dragRef] = useDrag({
    type: "item",
    item: { index: props.index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [spec, dropRef] = useDrop({
    accept: "item",
    hover: (item: any, monitor: any) => {
      const dragIndex = item.index;
      const hoverIndex = props.index;

      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top;

      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return
      if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return

      props.moveCountry(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const dragDropRef: any = dragRef(dropRef(ref))

  return (

    <div ref={dragDropRef} key={props.index} className="text-white col-md-2" style={{ opacity: isDragging ? 0 : 1 }}>
      <div className={styles.PopcountrBox}>
        <h6 className={styles.Lenght}> {props.index + 1}</h6>
        <img src={props.flag} alt='flag' width={20} height={20} />
        <p>{props.label}</p>
      </div>
    </div>
  );
};

export default PopularCountries;