import { useEffect, useState } from "react";
import styles from "../styles/style.module.css";
import commonStyles from "../../../lib/common/common.module.css";
import { LOCAL_CONSTANTS } from "../../../lib/constants";
import { list, ordersList, physicalEsim, userOrderlist } from "../api";
import Layout from "../../../ui/layout";
import { Pagination } from "../../../ui";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import UserList from "./userList";
import OrderList from "./orderList";

const OrdersList = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const { userId } = useParams();
    const navigate = useNavigate();
    const [pagination, setPagination] = useState({
        skip: 0,
        limit: LOCAL_CONSTANTS.limitCount,
    });
    const [activeTab, setActiveTab] = useState(1)
    const [niyoTabbing, setNiyoTabbing] = useState(0)

    const [activeData, setActiveData] = useState<any>([]);

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setLoading(true);
        const query: any = {
            userId,
            page: (1 + pagination.skip),
            limit: pagination.limit
        }

        userOrderlist(query).then((res: any) => {
            setLoading(false);
            if (activeTab == 1) setActiveData(res?.data?.freeEsimData)
            if (activeTab == 2) setActiveData(res?.data?.purchaseData)
        })


    }, [pagination, activeTab]);
    return (
        <Layout title="Orders List">
            <div className={commonStyles.tableData}>
                <div className={styles.mainTabbing}>
                    <div className="leftTabbing ">
                        <div className={`${styles.topCms} ${styles.simBarTab}`}>
                            <ul>
                                <li
                                    className={activeTab === 1 ? styles.active : ""}
                                    onClick={() => setActiveTab(1)}
                                >
                                    <p>Free eSim Order</p>
                                </li>

                                <li
                                    className={activeTab === 2 ? styles.active : ""}
                                    onClick={() => setActiveTab(2)}
                                >
                                    <p>Purchased eSim Order</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className="rightTabbing">
                        <button className={styles.loyalityPointBtn} onClick={() => navigate(`/partners/${partnerName}/${partnerWebsiteId}/partner-admin-list`)}>Admin List</button>
                        {
                            partnerName?.toLocaleLowerCase() == LOCAL_CONSTANTS.PARTNERS.VFS && <>
                                <button className={styles.loyalityPointBtn} onClick={() => navigate(`/partners/${partnerName}/${partnerWebsiteId}/generate_employee_qr_code`)}>Generate qr code for Employee </button>
                                <button className={styles.loyalityPointBtn} onClick={() => navigate(`/partners/${partnerName}/${partnerWebsiteId}/employees_list`)}>Employees List </button>
                            </>
                        }

                        {
                            partnerName?.toLocaleLowerCase() == LOCAL_CONSTANTS.PARTNERS.NIYO && activeTab === 2 && <>
                                <div className={styles.topCms}>
                                    <ul>
                                        <li
                                            className={niyoTabbing === 1 ? styles.active : ""}
                                            onClick={() => setNiyoTabbing(1)}>
                                            <p>Niyo</p>
                                        </li>
                                        <li
                                            className={niyoTabbing === 2 ? styles.active : ""}
                                            onClick={() => setNiyoTabbing(2)}>
                                            <p>Niyo Basic</p>
                                        </li>
                                    </ul>
                                </div>
                            </>
                        }
                    </div> */}
                </div>

                {/* {activeTab == 1 && <OrderList loading={loading} orders={activeData} />}
                {activeTab == 2 && <OrderList loading={loading} orders={activeData} />} */}

                <Pagination
                    paginationState={pagination}
                    setPaginationState={setPagination}
                    count={activeData.count}
                />
            </div>
        </Layout>
    );
};

export default OrdersList;
