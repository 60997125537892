import React, { ChangeEvent } from "react";
import toggleStyles from "./style.module.css";

interface Props {
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  value: boolean;
};


const Toggle = (props: Props) => {
  return (
    <label className={toggleStyles.switch}>
      <input
        type="checkbox"
        checked={props.value}
        name="isEnabled"
        onChange={props.handleChange}
      />
      <span className={`${toggleStyles.slider} ${toggleStyles.round}`}></span>
    </label>
  );
};

export default Toggle;