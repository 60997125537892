import { CouponTypes } from "../../../interfaces";
import { CommonTypes } from "../../../interfaces";
import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../lib/constants";

export const getCoupons = (query: CouponTypes.CouponListQuery): Promise<CouponTypes.CouponsListResponse> => {
	return axios.get(API_ROUTES.COUPONS.LIST, { params: query });
}

export const specificCoupon = (id: string): Promise<CouponTypes.SpecificCouponResponse> => {
	return axios.get(`${API_ROUTES.COUPONS.LIST}/${id}`);
}

export const createCoupon = (payload: CouponTypes.NewCouponPayload): Promise<CommonTypes.DefaultResponse> => {
	return axios.post(API_ROUTES.COUPONS.LIST, payload);
}

export const updateCoupon = (payload: CouponTypes.UpdateCouponPayload, promoId: string): Promise<CommonTypes.DefaultResponse> => {
	return axios.patch(`${API_ROUTES.COUPONS.UPDATE}/${promoId}`, payload);
}

export const removeCoupon = (id: string): Promise<CommonTypes.DefaultResponse> => {
	return axios.delete(`${API_ROUTES.COUPONS.LIST}/${id}`);
}
