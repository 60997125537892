import * as Yup from "yup";
import moment from "moment";

const PromoSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
    promoCode: Yup.string().required("Required"),
    discount: Yup
        .number().required("Required")
        .min(1, "Discount cannot be zero"),
    startDate: Yup.string().required("Required"),
    endDate: Yup.string().required("Required").test("voucher-date", function (endDate) {
        let { startDate } = this.parent;

        if (startDate && endDate) {
            const startDateMoment = moment(startDate);
            const endDateMoment = moment(endDate);

            if (!endDateMoment.isAfter(startDateMoment)) {
                throw new Yup.ValidationError(
                    "Promo end date cannot be less than start date",
                    endDate,
                    "endDate"
                );
            }
        }

        return true;
    }),
    minimumSpend: Yup
        .number()
        .required("Required")
        .min(1, "Amount cannot be zero")
        .test("minimum=spend", function (minimumSpend) {
            if (this.parent.type === 1 && minimumSpend < this.parent.discount) {
                throw new Yup.ValidationError(
                    "Minimum Amount cannot be less than discount amount",
                    minimumSpend,
                    "minimumSpend"
                );
            }

            return true;
        }),
    frequencyPerUser: Yup.number().required("Required"),
    type: Yup.number().required("Required").min(1, "Select discount type"),
    discountCurrency: Yup.string().test("discount-currency", "Select discount currency", function (value) {
        const discountType = this.parent.type;

        if (discountType === 2) {
            return true;
        } else if (!value) {
            throw new Yup.ValidationError(
                "Select discount currency",
                value,
                "discountCurrency"
            );
        }

        return true;
    }),
    minimumSpendCurrency: Yup.string().required("Select minimum spend currency"),
    countries: Yup.array().min(1, "Required"),
});

export default PromoSchema;