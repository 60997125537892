import { toast } from "react-toastify";
import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";

import styles from "../styles/viewPlans.module.css";
import commonStyles from '../../../lib/common/common.module.css';
import { PlanTypes } from "../../../interfaces";
import { APP_ROUTES, LOCAL_CONSTANTS } from "../../../lib/constants";
import { getPlans, updatePlan, updatePopularCountries } from "../api";
import { CONSTANTS } from "../../../lib/constants/messages";
import { Layout, Pagination } from "../../../ui";
import { Toggle } from "../../../components";

const ViewPlans = () => {
  const params = useParams();

  const [plans, setPlans] = useState<{ list: PlanTypes.Plan[], count: number }>({ list: [], count: 0 });
  const [loading, setLoading] = useState<boolean>(true);

  const [pagination, setPagination] = useState({
    skip: 0,
    limit: LOCAL_CONSTANTS.limitCount,
  });
  const [retailPrice, setPlanRetailPrice] = useState({ value: 0, planId: "" });
  const [offerPrice, setPlanOfferPrice] = useState({ value: 0, planId: "" });
  const [buyPrice, setPlanBuyPrice] = useState({ value: 0, planId: "" });
  const [activeTab, setActiveTab] = useState<number>(1);

  const listPlans = () => {
    let query: any = { 
      page: 1, 
      limit: 10,
      vendor : activeTab
     };

    if (window.location.pathname.startsWith('/plans/countries')) {
      query["search"] = params.id;
    }
    else query["search"] = params.id;

    getPlans(query).then(res => {
      const bundles = res.data.bundles;

      for (const item of bundles) {

        let SecondAmount = item.buyPrice ? item.buyPrice : item.apiPrice;
        let firstAmount = item.offerPrice ? item.offerPrice : item.retailPrice ? item.retailPrice : item.apiPrice;

        item.marginPrice = SecondAmount - firstAmount;
        item.marginPercent = item.marginPrice / 100;
      }
      setPlans({ list: bundles, count: res.data.count });
      setLoading(false);
    });
  };

  const togglePlanRetailPrice = (item: PlanTypes.Plan) => {
    if (retailPrice.planId !== item._id || !retailPrice.planId.length) {
      setPlanRetailPrice({ value: item.retailPrice ?? 0, planId: item._id })
    }
  };

  const togglePlanOfferPrice = (item: PlanTypes.Plan) => {
    if (item.retailPrice) {
      if (offerPrice.planId !== item._id || !offerPrice.planId.length) {
        setPlanOfferPrice({ value: item.offerPrice ?? 0, planId: item._id })
      }
    }
    else toast.error("You need to add retail amount before adding offer amount");
  };

  const togglePlanBuyPrice = (item: PlanTypes.Plan) => {
    if (buyPrice.planId !== item._id || !buyPrice.planId.length) {
      setPlanBuyPrice({ value: item.buyPrice ?? 0, planId: item._id })
    }
  };


  const updatePlanData = (plan: PlanTypes.Plan, newPlanPrice?: number, priceType?: string) => {
    let payload: any = { isActive: !plan.isActive };

    if (typeof newPlanPrice === "number") {
      let key = priceType === "retail" ? "retailPrice" : priceType === "buy" ? "buyPrice" : "offerPrice";
      payload = { [key]: newPlanPrice };
    }
    else {
      if (!plan.retailPrice && !plan.isActive) {
        toast.error(CONSTANTS.ENABLE_PLAN_WITHOUT_RETAIL_AMOUNT);
        return;
      }
    };

    toast.promise(
      updatePlan(payload, plan._id),
      {
        pending: {
          render() {
            return CONSTANTS.PLANS.UPDATING_STATUS;
          },
        },
        success: {
          render() {
            listPlans();
            setPlanRetailPrice({ planId: "", value: 0 });
            setPlanOfferPrice({ planId: "", value: 0 });
            setPlanBuyPrice({ planId: "", value: 0 });
            return CONSTANTS.PLANS.UPDATE_SUCCESS;
          }
        },
        error: {
          render() {
            return CONSTANTS.PLANS.UPDATE_FAILED;
          }
        }
      }
    )
  }

  const updateDocuments = (documentsRequired: boolean, planId: string) => {
    let payload: any = { documentsRequired };

    toast.promise(
      updatePlan(payload, planId),
      {
        pending: {
          render() {
            return CONSTANTS.PLANS.UPDATING_STATUS;
          },
        },
        success: {
          render() {
            listPlans();

            return CONSTANTS.PLANS.UPDATE_SUCCESS;
          }
        },
        error: {
          render({ data }: any) {
            return data.data.message;
          }
        }
      }
    )
  };

  const handleRetailPriceChange = (event: any) => {
    setPlanRetailPrice({ ...retailPrice, value: parseFloat(event.target.value) })
  };

  const handleOfferPriceChange = (event: any) => {
    setPlanOfferPrice({ ...offerPrice, value: parseFloat(event.target.value) })
  };

  const handleBuyPriceChange = (event: any) => {
    setPlanBuyPrice({ ...buyPrice, value: parseFloat(event.target.value) })
  };

  useEffect(() => {
    listPlans();
  }, [activeTab]);

  const handelPopularCountry = (e:any) => {
    console.log("bvmcvbm ", e.target.value)
    // updatePopularCountries()
  }

  return (
    <Layout title="Plans">
      <div className={commonStyles.tableData}>
        <div className={commonStyles.mainTabbing}>
          <div className="leftTabbing">
            <div className={commonStyles.topCms}>
              <ul>
                <li  className={activeTab === 1 ? commonStyles.active : ""}
                onClick={()=>{
                  setActiveTab(1)
                }}><p>eSimgo</p></li>
                <li className={activeTab === 2 ? commonStyles.active : ""}
                onClick={()=>{
                  setActiveTab(2)
                }}><p>Joytel</p></li>
              </ul>
            </div>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              {LOCAL_CONSTANTS.countryPlansHeaders?.map((header: any, index) => (
                (header.label !== "Product Code" || activeTab === 2) && (
                  <th
                    className={`${header?.value ? "cursor-pointer" : ""}`}
                    key={index}
                  >
                    {header.label}
                  </th>
                )
              ))}
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={12} align="center" className={styles.loadingText}>
                  {LOCAL_CONSTANTS.loading}
                </td>
              </tr>
            )}
            {!loading && plans.list.length === 0 && (
              <tr>
                <td colSpan={12} align="center">
                  No Plan Found
                </td>
              </tr>
            )}
            {!loading &&
              plans.list.map((item:any) => (
                <tr key={item._id}>
                  <td>
                    <input type="checkbox" onChange={handelPopularCountry} checked={item.isCountryPopularBundle} name="popularCountries" value={item._id} />
                  </td>
                  <td>{item.name}</td>
                  <td>{item?.displayName}</td>
                  <td>{item?.vendor === 1 ? "eSimgo" : "Joytel" }</td>
                  <td>{item?.description}</td>
                  { activeTab === 2 &&
                    <td>{item?.productCode}</td>
                  }
                  <td>{item.duration} Days</td>
                  <td>{item.dataAmount}</td>
                  <td>{item.speed ? item.speed.join("/") : "-"}</td>
                  <td>{item.priceCurrency} {item.apiPrice}</td>
                  <td className="cursor-pointer" onClick={() => togglePlanRetailPrice(item)}>
                    {
                      item._id === retailPrice.planId ?
                        <Fragment>
                          <input
                            className={styles.input}
                            value={retailPrice.value}
                            type="number"
                            onChange={handleRetailPriceChange}
                            onKeyDown={(event) => {
                              if (event.keyCode === 13) updatePlanData(item, retailPrice.value, "retail");
                            }}
                          />
                          <FontAwesomeIcon
                            className={styles.removePrice}
                            onClick={() => setPlanRetailPrice({ planId: "", value: 0 })}
                            icon={faTimes}
                          />

                        </Fragment>
                        :
                        item.retailPrice ? `${item.priceCurrency} ${item.retailPrice}` : "-"
                    }
                  </td>
                  <td className="cursor-pointer" onClick={() => togglePlanOfferPrice(item)}>
                    {
                      item._id === offerPrice.planId ?
                        <Fragment>
                          <input
                            className={styles.input}
                            value={offerPrice.value}
                            type="number"
                            onChange={handleOfferPriceChange}
                            onKeyDown={(event) => {
                              if (event.keyCode === 13) updatePlanData(item, offerPrice.value, "offer");
                            }}
                          />

                          <FontAwesomeIcon
                            className={styles.removePrice}
                            onClick={() => setPlanOfferPrice({ planId: "", value: 0 })}
                            icon={faTimes}
                          />
                        </Fragment>
                        :
                        item.offerPrice ? `${item.priceCurrency} ${item.offerPrice}` : "-"
                    }
                  </td>
                  <td className="cursor-pointer" onClick={() => togglePlanBuyPrice(item)}>
                    {
                      item._id === buyPrice.planId ?
                        <Fragment>
                          <input
                            className={styles.input}
                            value={buyPrice.value}
                            type="number"
                            onChange={handleBuyPriceChange}
                            onKeyDown={(event) => {
                              if (event.keyCode === 13) updatePlanData(item, buyPrice.value, "buy");
                            }}
                          />

                          <FontAwesomeIcon
                            className={styles.removePrice}
                            onClick={() => setPlanBuyPrice({ planId: "", value: 0 })}
                            icon={faTimes}
                          />
                        </Fragment>
                        :
                        item.buyPrice ? `${item.priceCurrency} ${item.buyPrice}` : "-"
                    }
                  </td>
                  <td>{item.marginPrice?.toFixed(2)}</td>
                  <td>{(item.marginPercent < 0 ? item.marginPercent * -1 : item.marginPercent)?.toFixed(2)}%</td>
                  <td>{item.bundleSalesCount}</td>
                  <td><Toggle handleChange={() => updatePlanData(item)} value={item.isActive} /></td>
                  <td><Toggle handleChange={() => updateDocuments(!item.documentsRequired, item._id)} value={item.documentsRequired} /></td>
                </tr>
              ))}
          </tbody>
        </table>
        <Pagination
          paginationState={pagination}
          setPaginationState={setPagination}
          count={plans.count}
        />
      </div>


    </Layout>
  );
};

export default ViewPlans;