import { BlogTypes, CouponTypes, CommonTypes } from "../../../interfaces";
import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../lib/constants";

export const create = (payload: BlogTypes.CreateBlogPayload): Promise<CommonTypes.DefaultResponse> => {
  return axios.post(API_ROUTES.BLOGS.CREATE, payload);
}

export const updateBlog = (payload: BlogTypes.UpdateBlogPayload): Promise<CommonTypes.DefaultResponse> => {
  return axios.patch(API_ROUTES.BLOGS.UPDATE, payload);
}

export const getBlogs = (query: BlogTypes.BlogListQuery): Promise<BlogTypes.BlogListResponse> => {
  return axios.get(API_ROUTES.BLOGS.LIST, { params: query });
}

export const removeBlog = (id: string): Promise<CommonTypes.DefaultResponse> => {
  return axios.delete(`${API_ROUTES.BLOGS.SPECIFIC}/${id}`);
}

export const updateBlogStatus = (payload: BlogTypes.UpdateBlogStatusPayload): Promise<CommonTypes.DefaultResponse> => {
  return axios.patch(`${API_ROUTES.BLOGS.UPDATE_BLOG}`, payload);
}

export const uploadFile = (formData: FormData): Promise<CouponTypes.FileUploadResponse> => {
  return axios.post(`${API_ROUTES.UPLOAD_FILE}`, formData);
};

export const specificBlog = (id: string): Promise<BlogTypes.SpecificBlogResponse> => {
  return axios.get(`${API_ROUTES.BLOGS.SPECIFIC}/${id}`);
}

export const specificBlogComments = (params: BlogTypes.GetBlogCommentsQuery): Promise<BlogTypes.BlogCommentsResponse> => {
  return axios.get(API_ROUTES.BLOGS_COMMENTS.LIST, { params })
}

export const updateBlogComment = (payload: BlogTypes.UpdateBlogCommentStatusPayload): Promise<CommonTypes.DefaultResponse> => {
  return axios.patch(API_ROUTES.BLOGS_COMMENTS.UPDATE, payload);
}