import React, { useEffect, useState } from "react";
import MarkdownIt from "markdown-it";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserAlt, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import styles from "../styles/specific.module.css";
import { specificBlog, specificBlogComments, updateBlogComment } from "../api";
import { BlogTypes } from "../../../interfaces";
import { BLOG_COMMENT_STATUS, LOCAL_CONSTANTS, ROLE_TYPE } from "../../../lib/constants";
import { CONSTANTS } from "../../../lib/constants/messages";
import { Layout, Popup } from "../../../ui";

const SpecificBlog = () => {
  const params = useParams();

  const [blog, setBlog] = useState<BlogTypes.Blogs>();
  const [loading, setLoading] = useState<boolean>(true);
  const [blogComments, setBlogComments] = useState<{ list: BlogTypes.BlogComment[], count: number }>({ list: [], count: 0 });
  const [commentPagination, setCommentPagination] = useState({ limit: 1, skip: 0 });
  const [dialog, setDialog] = useState({ show: false, id: "" });

  const mdParser = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
  });

  const getBlogComments = (id: string) => {
    const query = {
      ...commentPagination,
      blog: id,
    };

    specificBlogComments(query).then(res => {
      const { data } = res;
      setBlogComments({ list: [...data.blogComments, ...blogComments.list], count: data.count });
    })
  };

  useEffect(() => {
    if (params.id) {
      specificBlog(params.id as string)
        .then(res => {
          setBlog(res.data.blog);
          setLoading(false);
        });

    }
  }, [params.id]);

  useEffect(() => {
    getBlogComments(params.id as string);
  }, [params.id, commentPagination.skip])

  const approveDisapproveComment = () => {
    toast.promise(
      updateBlogComment({ blogCommentId: dialog.id, status: 2 }),
      {
        pending: {
          render() {
            return CONSTANTS.BLOG_COMMENT.UPDATING_STATUS;
          },
        },
        success: {
          render() {
            setDialog({ show: false, id: "" });
            return CONSTANTS.BLOG_COMMENT.UPDATE_STATUS_SUCCESS;
          },
        },
        error: {
          render({ data }: any) {
            return CONSTANTS.BLOG_COMMENT.UPDATE_STATUS_FAILED;
          },
        },
      });
  };

  const loadMoreComments = () => {
    setCommentPagination({ skip: commentPagination.skip + 1, limit: commentPagination.limit });
  };

  if (loading) {
    return <Layout title="Blog" ><p className={styles.Loading}>Loading</p></Layout>
  }

  return (
    <Layout title="Blog">
      <div className={styles.outerBlog}>
        <div className={styles.container}>
          <h2 className="text-white">{blog?.title}</h2>
          <h4>Created By <span>{blog?.createdBy.fullName}</span> on <span>{moment(blog?.createdAt).format("YYYY-MM-DD")}</span></h4>
          <div className="d-flex justify-content-center my-4">
            <img width={300} height={300} src={blog?.image as string} alt="user" className={styles.blogImage} />
          </div>
          {
            blog?.topic &&
            <div
              className="text-white"
              dangerouslySetInnerHTML={{
                __html: mdParser.render(blog?.topic as string),
              }}
            />
          }
        </div>
      </div>
      <div className={styles.CommentsSec}>
        <h5>Comments</h5>

        <div className={styles.container}>
          {blogComments.list.length === 0 && <p className="text-center text-white mb-0">No Comments Found</p>}
          <div className={styles.comentScroll}>
            {blogComments.list.map(item => (
              <div key={item._id} className={styles.commnetBox}>
                <div className={styles.commentHead}>
                  <span className={styles.usrImage}>
                    <FontAwesomeIcon
                      size="3x"
                      className="me-2"
                      icon={faUserAlt}
                    />
                  </span>
                  <div className={styles.Usrnm}>
                    <h6 className="text-white">
                      {item.user.fullName}
                      <span>{BLOG_COMMENT_STATUS[item.status]}</span>
                    </h6>
                    <p className="text-white">{moment(item.createdAt).fromNow()}</p>
                  </div>
                </div>

                <p className="text-white">{item.comment}</p>

                {/* <p className="text-white">{item.status}</p> */}
                {
                  item.user.role === ROLE_TYPE.USER ?
                    <span className={styles.thumb}>
                      {
                        item.status === 1 ?
                          <FontAwesomeIcon
                            size="3x"
                            className="me-2"
                            icon={faThumbsUp}
                            onClick={() => setDialog({ show: true, id: item._id })}
                          />
                          :
                          null
                      }
                    </span>
                    :
                    null
                }
              </div>
            ))}
          </div>

          {blogComments.list.length < blogComments.count && <p className="loadmore" onClick={loadMoreComments}>Load More Comments</p>}
        </div>
      </div>
      <Popup
        show={dialog.show}
        handleClose={() => setDialog({ show: false, id: "" })}
        heading={LOCAL_CONSTANTS.areYouSure}
        content={LOCAL_CONSTANTS.approveComment}
        handleSubmit={approveDisapproveComment}
        id={dialog.id}
      />
    </Layout>
  );
};

export default SpecificBlog;