import React from "react";
import ReactPaginate from "react-paginate";
import { faAngleDoubleLeft, faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./style.module.css"
import { PaginationTypes } from "../../interfaces";

const Pagination = ({ paginationState, setPaginationState, count }: PaginationTypes.PaginationPropInterface) => {

	const handlePageClick = (selectedPage: { selected: number }) => {
		setPaginationState((prev) => {
			return { ...prev, skip: selectedPage.selected };
		});
	}

	return (
		count && count >= paginationState.limit ? (
			<ReactPaginate
				className={styles.paginationContainer}
				activeClassName={styles.selected}
				pageLinkClassName={styles.pageLink}
				breakLabel="..."
				nextLabel={<FontAwesomeIcon size="xs" icon={faAngleDoubleRight} />}
				pageClassName={styles.page}
				onPageChange={handlePageClick}
				pageCount={Math.ceil(count / paginationState.limit)}
				previousClassName={styles.paginateButton}
				nextClassName={styles.paginateButton}
				previousLinkClassName={styles.paginateLink}
				nextLinkClassName={styles.paginateLink}
				previousLabel={<FontAwesomeIcon size="xs" icon={faAngleDoubleLeft} />}
				disableInitialCallback={false}
				marginPagesDisplayed={2}
				pageRangeDisplayed={3}
				forcePage={paginationState.skip}
			/>) : null
	)
}

export default Pagination