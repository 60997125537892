import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import moment from "moment";
import Rating from "react-star-ratings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faMessage, faEye, faPencil } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

import commonStyles from "../../../lib/common/common.module.css";
import styles from "../styles/list.module.css";
import { APP_ROUTES, BACKEND_BLOG_STATUS, BLOG_STATUS, BLOG_TYPE, LOCAL_CONSTANTS } from "../../../lib/constants";
import { CONSTANTS } from "../../../lib/constants/messages";
import { AcceptRejectPopup, ActionButton, DropDownMenu, Layout, Pagination, Popup } from "../../../ui";
import { getBlogs, removeBlog, updateBlogStatus } from "../api";
import { BlogTypes } from "../../../interfaces";

const ListBlogs = () => {
  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    skip: 0,
    limit: LOCAL_CONSTANTS.limitCount,
  });
  const [search, setSearch] = useState<string>("");
  const [searchName, setSearchName] = useState<string>("");
  const [order, setSortOrder] = useState<number>(0);
  const [sortBy, setSortColumn] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [blogs, setBlogs] = useState<{ list: BlogTypes.Blogs[]; count: number }>({
    list: [],
    count: 0,
  });
  const [dialog, setDialog] = useState({ show: false, id: "" });
  const [approveBlogDialog, setApproveBlogDialog] = useState({ show: false, type: "", id: "", isApproved: 0 });

  const [blogType, setBlogType] = useState<number | null>(null);
  const [blogStatus, setBlogStatus] = useState<number | null>(null);

  const listBlogs = (
    pageIndex = pagination.skip,
    blogType: number | null = null,
    blogStatus: number | null = null
  ) => {
    let query: any = {
      page: (1 + pageIndex),
      limit: pagination.limit,
      searchByTitle: search,
      searchByName: searchName,
      order,
      sortBy,
    };

    if (blogType) {
      query["blogType"] = blogType;
    }

    if (blogStatus) {
      query["blogStatus"] = blogStatus;
    }

    getBlogs(query).then(({ data }) => {
      setBlogs({ list: data.blogs, count: data.count });
      setLoading(false);
    });
  };

  const handleSort = (column: string) => {
    setSortColumn(column);
    setSortOrder(
      order === LOCAL_CONSTANTS.descKey
        ? LOCAL_CONSTANTS.ascKey
        : LOCAL_CONSTANTS.descKey
    );

    const query = {
      ...pagination,
      search,
      order:
        order === LOCAL_CONSTANTS.descKey
          ? LOCAL_CONSTANTS.ascKey
          : LOCAL_CONSTANTS.descKey,
      sortBy: column,
    };

    getBlogs(query).then(({ data }) => {
      setBlogs({ list: data.blogs, count: data.count });
      setLoading(false);
    });
  };

  const renderSortIcon = (column: string) => {
    if (sortBy === column) {
      return order === LOCAL_CONSTANTS.descKey ? " ▼" : " ▲";
    }
    return null;
  };

  const deleteBlogs = () => {
    toast.promise(
      removeBlog(dialog.id),
      {
        pending: {
          render() {
            return CONSTANTS.BLOGS.REMOVING;
          },
        },
        success: {
          render() {
            listBlogs();

            setDialog({ show: false, id: "" });
            return CONSTANTS.BLOGS.REMOVE_SUCCESS;
          },
        },
        error: {
          render({ data }: any) {
            return data.data.message;
          },
        },
      });
  };


  const changeBlogStatus = () => {
    const payload = {
      blogId: approveBlogDialog.id,
      status: approveBlogDialog.isApproved
    };

    toast.promise(
      updateBlogStatus(payload),
      {
        pending: {
          render() {
            return CONSTANTS.BLOGS.UPDATING_STATUS;
          },
        },
        success: {
          render() {
            listBlogs(0, blogType, blogStatus);

            setApproveBlogDialog({ show: false, id: "", type: "", isApproved: 0 })
            return CONSTANTS.BLOGS.UPDATE_STATUS_SUCCESS;
          },
        },
        error: {
          render({ data }: any) {
            return CONSTANTS.BLOGS.UPDATE_STATUS_FAILED;
          },
        },
      });
  };

  useEffect(() => {
    setLoading(true);

    const debounceSearch = setTimeout(() => {
      listBlogs(pagination.skip, blogType, blogStatus);
    }, 500);
    return () => {
      clearTimeout(debounceSearch);
    };
  }, [pagination.skip]);

  return (
    <Layout title="Blogs">
      <div className={commonStyles.tableData}>
        <div className={commonStyles.topFilter}>
          <div className="d-flex">
            <div className="me-3">
              <p className="mb-0 text-white">Title</p>
              <input
                type="search"
                placeholder="Search by title"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onKeyDown={(event) => {
                  if (event.keyCode === 13) {
                    setLoading(true);
                    const debounceSearch = setTimeout(() => {
                      listBlogs(0, blogType, blogStatus);
                    }, 500);
                    return () => {
                      clearTimeout(debounceSearch);
                    };
                  };
                }}
              />
            </div>
            <div>
              <p className="mb-0 text-white">User name</p>
              <input
                type="search"
                placeholder="Search by name"
                onChange={(e) => {
                  setSearchName(e.target.value);
                }}
                onKeyDown={(event) => {
                  if (event.keyCode === 13) {
                    setLoading(true);
                    const debounceSearch = setTimeout(() => {
                      listBlogs(0, blogType, blogStatus);
                    }, 500);
                    return () => {
                      clearTimeout(debounceSearch);
                    };
                  };
                }}
              />
            </div>
          </div>
          <div className={`d-flex align-items-end gap-3  ${styles.filterSlect}`}>

            <div className="w-100">
              <p className="mb-0 text-white">Blog Status</p>
              <Select
                options={LOCAL_CONSTANTS.blogStatus as any}
                onChange={(response) => {
                  if (response) {
                    let pageIndex = 0;
                    let blogStatus = response.value;

                    listBlogs(pageIndex, blogType, blogStatus);
                    setBlogStatus(blogStatus);
                  }
                }}
                defaultValue={LOCAL_CONSTANTS.blogStatus[0]}
                className="w-100"
              />
            </div>

            <div className="w-100">
              <p className="mb-0 text-white">Blog Type</p>
              <Select
                options={LOCAL_CONSTANTS.blogTypes as any}
                onChange={(response) => {
                  if (response) {
                    let pageIndex = 0;
                    let blogType = response.value;

                    listBlogs(pageIndex, blogType, blogStatus);
                    setBlogType(blogType);
                  }
                }}
                defaultValue={LOCAL_CONSTANTS.blogTypes[0]}
                className="w-100"
              />
            </div>
            <button onClick={() => navigate(APP_ROUTES.NEW_BLOG)} className={styles.newBlog} >+ Add new blog</button>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              {LOCAL_CONSTANTS.blogsTableHeaders?.map((header: any) => (
                <th
                  className={`${header?.value
                    ? "cursor-pointer"
                    : ""
                    }`}
                  onClick={() => {
                    if (header?.value) {
                      handleSort(header?.value);
                    }
                  }}
                  key={header?.value}
                >
                  {header?.label}
                  {sortBy === header?.value &&
                    header?.value &&
                    renderSortIcon(header?.value)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={7} align="center">
                  {LOCAL_CONSTANTS.loading}
                </td>
              </tr>
            )}
            {!loading && blogs.list.length === 0 && (
              <tr>
                <td colSpan={7} align="center">
                  {LOCAL_CONSTANTS.noBlogFound}
                </td>
              </tr>
            )}
            {!loading &&
              blogs.list.map((item) => (
                <tr key={item._id}>
                  <td><img width={40} height={40} src={item.image} alt="user" className={styles.blogImage} /></td>
                  <td className={styles.title}>{item.title.length > 40 ? `${item.title.slice(0, 40)}...` : item.title}</td>
                  <td>{BLOG_STATUS[item.status]}</td>
                  <td>
                    <Rating
                      rating={item.rating}
                      numberOfStars={5}
                      starDimension="15px"
                      starRatedColor="#03FE9D"
                      starHoverColor="#03FE9D"
                    />
                  </td>
                  <td>{item.userFullName}</td>
                  <td>{moment(item?.createdAt).format(LOCAL_CONSTANTS.dateFormat)}</td>
                  <td>
                    <Dropdown>
                      <div className="cursor-pointer">
                        <Dropdown.Toggle as={ActionButton}></Dropdown.Toggle>
                      </div>
                      <Dropdown.Menu as={DropDownMenu}>
                        <Dropdown.Item onClick={() => navigate(`${APP_ROUTES.BLOGS}/${item._id}`)}>
                          <FontAwesomeIcon
                            size="xs"
                            className="me-2"
                            icon={faEye}
                          />
                          View
                        </Dropdown.Item>
                        {item.type === BLOG_TYPE.ADMIN &&
                          <Dropdown.Item onClick={() => navigate(`${APP_ROUTES.BLOGS}/${item._id}/edit`)}>
                            <FontAwesomeIcon
                              size="xs"
                              className="me-2"
                              icon={faPencil}
                            />
                            Edit
                          </Dropdown.Item>
                        }
                        <Dropdown.Item onClick={() => setDialog({ show: true, id: item._id })}>
                          <FontAwesomeIcon
                            size="xs"
                            className="me-2"
                            icon={faTrashAlt}
                          />
                          Delete
                        </Dropdown.Item>
                        {item.type === BLOG_TYPE.USER &&
                          <Dropdown.Item
                            onClick={() => {
                              let isApproved = [BACKEND_BLOG_STATUS.PENDING, BACKEND_BLOG_STATUS.REJECTED]
                                .includes(item.status) ? BACKEND_BLOG_STATUS.APPROVED : BACKEND_BLOG_STATUS.REJECTED;

                              setApproveBlogDialog({
                                show: true,
                                isApproved: isApproved,
                                id: item._id,
                                type: item.status === BACKEND_BLOG_STATUS.PENDING ?
                                  LOCAL_CONSTANTS.pendingBlog :
                                  item.status === BACKEND_BLOG_STATUS.APPROVED ?
                                    LOCAL_CONSTANTS.approvedBlog :
                                    LOCAL_CONSTANTS.rejectBlog
                              })
                            }}>
                            <FontAwesomeIcon
                              size="xs"
                              className="me-2"
                              icon={faMessage}
                            />
                            Change Status
                          </Dropdown.Item>
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Pagination
        paginationState={pagination}
        setPaginationState={setPagination}
        count={blogs.count}
      />
      <Popup
        show={dialog.show}
        handleClose={() => setDialog({ show: false, id: "" })}
        heading={LOCAL_CONSTANTS.areYouSure}
        content={LOCAL_CONSTANTS.removeBlog}
        handleSubmit={deleteBlogs}
        id={dialog.id}
      />

      <AcceptRejectPopup
        show={approveBlogDialog.show}
        handleClose={() => setApproveBlogDialog({ show: false, id: "", type: "", isApproved: 0 })}
        heading={LOCAL_CONSTANTS.areYouSure}
        content={approveBlogDialog.type}
        handleSubmit={changeBlogStatus}
        id={dialog.id}
        changeStatus={true}
        closeButtonText="Reject"
        acceptButtonText="Approve"
      />
    </Layout>
  );
};

export default ListBlogs;