import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import styles from "../styles/viewPlans.module.css";
import commonStyles from '../../../lib/common/common.module.css';
import { PlanTypes } from "../../../interfaces";
import { LOCAL_CONSTANTS } from "../../../lib/constants";
import { CONSTANTS } from "../../../lib/constants/messages";
import { Layout, Pagination } from "../../../ui";
import { Toggle } from "../../../components";
import { activePartnerEsimBundles, getPartnerEsimBundles } from "../api";

const PartnerViewPlans = () => {
  const params = useParams();

  const { partnerName, partnerWebsiteId } = useParams();
  // const navigate = useNavigate();

  const [plans, setPlans] = useState<{ list: PlanTypes.Plan[], count: number }>({ list: [], count: 0 });
  const [loading, setLoading] = useState<boolean>(true);

  const [pagination, setPagination] = useState({
    skip: 0,
    limit: LOCAL_CONSTANTS.limitCount,
  });
  const [activeTab, setActiveTab] = useState<number>(1);
  const [search, setSearch] = useState("");

  const listPlans = () => {
    let query: any = { 
      page: ( 1 + pagination.skip ), 
      limit: pagination.limit,
      vendor : activeTab,
      partnerWebsiteId : partnerWebsiteId
     };
     if(search) query.search = search;

    getPartnerEsimBundles(query).then(res => {
      setPlans({ list:res.data.bundles, count: res.data.count });
      setLoading(false);
    });
  };

  const updatePlanData = (plan : any) => {
    let payload: any = { 
      isActive: !plan.isActive, 
      bundleId: plan._id,
      partnerWebsiteId,
    };

    toast.promise(
      activePartnerEsimBundles(payload),
      {
        pending: {
          render() {
            return CONSTANTS.PLANS.UPDATING_STATUS;
          },
        },
        success: {
          render() {
            listPlans();
            return CONSTANTS.PLANS.UPDATE_SUCCESS;
          }
        },
        error: {
          render() {
            return CONSTANTS.PLANS.UPDATE_FAILED;
          }
        }
      }
    )
  }

  useEffect(() => {
    listPlans();
  }, [activeTab, pagination, search]);

  return (
    <Layout title={`Plans for ${partnerName}`}>
      <div className={commonStyles.tableData}>
        <div className={commonStyles.mainTabbing}>
          <div className="leftTabbing">
            <div className={commonStyles.topCms}>
              <ul>
                <li  className={activeTab === 1 ? commonStyles.active : ""}
                onClick={()=>{
                  setActiveTab(1)
                  setPagination({...pagination, skip : 0})
                  setSearch("")
                }}><p>eSimgo</p></li>
                <li className={activeTab === 2 ? commonStyles.active : ""}
                onClick={()=>{
                  setActiveTab(2)
                  setPagination({...pagination, skip : 0})
                  setSearch("")
                }}><p>Joytel</p></li>
              </ul>
            </div>
            <div className="rightTabbing">
                  <input
                    type="search"
                    placeholder="Search by name or email"
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setPagination((prev) => ({ ...prev, skip: 0 }));
                    }}
                    value={search}
                  />
                </div>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              {LOCAL_CONSTANTS.partnerCountryPlansHeaders?.map((header: any, index) => (
                  <th
                    className={`${header?.value ? "cursor-pointer" : ""}`}
                    key={index}
                  >
                    {header.label}
                  </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={12} align="center" className={styles.loadingText}>
                  {LOCAL_CONSTANTS.loading}
                </td>
              </tr>
            )}
            {!loading && plans.list.length === 0 && (
              <tr>
                <td colSpan={12} align="center">
                  No Plan Found
                </td>
              </tr>
            )}
            {!loading &&
              plans.list.map((item:any) => (
                <tr key={item._id}>
                  <td>{item?.displayName}</td>
                  <td>{item?.vendor === 1 ? "eSimgo" : "Joytel" }</td>
                  <td>{item?.description}</td>
                  <td>{item.duration} Days</td>
                  <td>{item.dataAmount}</td>
                  <td><Toggle handleChange={() => updatePlanData(item)} value={item.isActive} /></td></tr>
              ))}
          </tbody>
        </table>
        <Pagination
          paginationState={pagination}
          setPaginationState={setPagination}
          count={plans.count}
        />
      </div>
    </Layout>
  );
};

export default PartnerViewPlans;