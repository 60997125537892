import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Select from "react-select";

import styles from "../styles/analytics.module.css";
import { analyticsRevenueGraph, getAnalyticsCard } from "../api";
import { AnalyticsTypes, OrderTypes } from "../../../interfaces";
import { MONTH_NAMES } from "../../../lib/constants";
import { Layout } from "../../../ui";
import { list } from "../../orders/api";
import { IMAGES } from "../../../assets/images";

const Analytics = () => {
  const [analyticsCards, setAnalyticsCard] = useState<AnalyticsTypes.AnalyticsCard>();
  const [revenueGraph, setRevenueGraph] = useState<AnalyticsTypes.Graph>({
    options: {
      xaxis: { categories: [] },
      stroke: { curve: "smooth" },
      chart: { zoom: { enabled: false }, toolbar: { tools: { download: false } } }
    },
    series: [
      { name: "series-1", data: [] }
    ],
    revenue: [],
  });
  const [revenueFilter, setRevenueFilter] = useState<{ filter: [], selectedFilter: {} }>({ filter: [], selectedFilter: {} });
  const [payments, setPaymentsList] = useState<{
    list: OrderTypes.Payment[];
    count: number;
  }>({
    list: [],
    count: 0,
  });

  useEffect(() => {
    getAnalyticsCard().then(res => {
      setAnalyticsCard(res.data);
    });

    const query: any = {
      page: 1,
      limit: 5,
    };
    list(query).then(({ data }) => {
      setPaymentsList({ list: data.orders, count: data.count });
    });

    analyticsRevenueGraph().then(res => {
      const yearFilter: any = res.data.map(item => ({ label: item._id, value: item._id }));

      setRevenueFilter({ filter: yearFilter, selectedFilter: yearFilter[0] });

      if (res.data.length) {

        setRevenueGraph({
          ...revenueGraph,
          options: {
            ...revenueGraph.options,
            xaxis: { categories: MONTH_NAMES },
          },
          series: [
            { name: "Revenue", data: res.data[0].monthlyStats }
          ],
          revenue: res.data,
        });
      }

    });
  }, [])

  return (
    <Layout title="Analytics">
      <div className={styles.graphList}>
        <ul>
          <li className={styles.active}>
            <div className={styles.innerGraph}>
              <h4>Revenue</h4>
              <h6>${analyticsCards?.revenue}</h6>
            </div>
          </li>

          <li>
            <div className={styles.innerGraph}>
              <h4>Active Plans</h4>
              <h6 className="text-white">{analyticsCards?.bundlesActiveCount}</h6>
            </div>
          </li>

          <li>
            <div className={styles.innerGraph}>
              <h4>Total Pack Sale</h4>
              <h6 className="text-white">{analyticsCards?.totalBundleSale}</h6>
            </div>
          </li>

          <li>
            <div className={styles.innerGraph}>
              <h4>Total Users</h4>
              <h6 className="text-white">{analyticsCards?.usersCount}</h6>
            </div>
          </li>
        </ul>
      </div>

      <div className={styles.linechart}>
        <div className="row">
          <div className="col-md-8">
            <div className={styles.lineChartLeft}>
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="text-white">Revenue</h4>
                <Select
                  options={revenueFilter.filter}
                  onChange={(response: any) => {
                    if (response) {
                      const values = revenueGraph.revenue.find(item => item._id === response.value);

                      if (values) {
                        setRevenueGraph({
                          ...revenueGraph,
                          series: [
                            { name: "Revenue", data: values.monthlyStats }
                          ],
                        });
                      }
                    }
                  }}
                  value={revenueFilter.selectedFilter}
                />
              </div>
              <ReactApexChart
                options={revenueGraph.options}
                series={revenueGraph.series}
                type="line"
                width='100%'
                height={420}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className={styles.lineChartRight}>
              <div className={styles.chartTopHead}>
                <h4> Top Bundle purchased this month</h4>
                {/* <a href="#">See all</a> */}
              </div>
              <ul>
                {!analyticsCards?.topPlans ? <li className="text-center text-white">No Top Plans Found</li> : null}
                {analyticsCards?.topPlans && analyticsCards?.topPlans.map((item, index) => (
                  <li key={index}>
                    <span>
                      <img src={item.flagImageUrl} alt="defaultUser" />
                    </span>
                    <div className={styles.pageNotify}>
                      <h6>{item.name} <label>{item.count}</label></h6>
                      <p>{item.dataAmount / 1000} GB / {item.duration} Days</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.growRte}>
        <div className="row">
          {/* <div className="col-md-6">
            <div className={styles.growrateLeft}>
              <div className={styles.growrateLeftHead}>
                <h4>Growth Rate </h4>
                <div className={styles.groBtn}>
                  <button className={styles.active}>Profits</button>
                  <button>User</button>
                </div>
              </div>


              <div className={styles.growList}>
                <div className={styles.gowlitBtn}>
                  <button className={styles.active}>Recent</button>
                  <button>Last Month</button>
                  <button>6 Months</button>
                </div>
                <ul>
                  <li><label>USA</label><label>$5667</label><span><Image width={100} height={100} src="/assets/images/up.png" alt="defaultUser" />0.22%</span></li>
                  <li><label>India</label><label>$5667</label><span><Image width={100} height={100} src="/assets/images/up.png" alt="defaultUser" />0.22%</span></li>
                  <li><label>UK</label><label>$5667</label><span><Image width={100} height={100} src="/assets/images/up.png" alt="defaultUser" />0.22%</span></li>
                  <li><label>USA</label><label>$5667</label><span><Image width={100} height={100} src="/assets/images/up.png" alt="defaultUser" />0.22%</span></li>
                  <li><label>Itali</label><label>$5667</label><span><Image width={100} height={100} src="/assets/images/up.png" alt="defaultUser" />0.22%</span></li>
                </ul>
              </div>
            </div>
          </div> */}
          <div className="col-md-12">
            <div className={styles.growrateRight}>
              <div className={styles.growrateRightHead}>
                <h4><img width={60} height={60} src={IMAGES.HourGlassIcon} alt="defaultUser" />Order History</h4>
                <a href="#">See all</a>
              </div>
              <ul>
                {payments.list.length === 0 ? <h3 className="text-center text-white mb-0">No orders found</h3> : null}
                {payments.list.map(item => (
                  <li key={item._id}><span>1 <img width={100} height={100} src={IMAGES.CommbatchIcon} alt="defaultUser" /> {item.bundle.name} ({item.bundle.dataAmount / 1000} GB /{item.bundle.duration} days)</span>
                    <h6>{item.priceCurrency} {item.price}</h6>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-md-12">
            <div className={styles.growrateLeft}>
              <div className={styles.growrateLeftHead}>
                <h4>Active Countries </h4>
                <div className={styles.groBtn}>
                  <button className={styles.active}>Profits</button>
                  <button>User</button>
                </div>
              </div>

              <div className={`${styles.growList} ${styles.newGrow}`}>
                <Image width={900} height={500} src="/assets/images/lkr.png" alt="defaultUser" />
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </Layout>
  );
};


export default Analytics;
