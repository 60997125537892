import React, { forwardRef, Ref } from "react";

import { ButtonTypes } from "../../../interfaces";
import { IMAGES } from "../../../assets/images";


const ActionButton = forwardRef(
	(props: ButtonTypes.ActionButtonProps, ref: Ref<HTMLAnchorElement>) => (
		<img onClick={(e) => props.onClick(e)} height={20} width={20} src={IMAGES.ThreeDotIcon} alt="threeDot" />
	)
);

export default ActionButton;