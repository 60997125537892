import * as Yup from "yup";

const localPlanSettingsSchema = Yup.object().shape({
  local: Yup.string().required("Required")
});

const multiversePlanSettingsSchema = Yup.object().shape({
  multiverse: Yup.string().required("Required")
});

const planDetailsSchema = Yup.object().shape({
  network: Yup.string().required("Required"),
  planType: Yup.string().required("Required"),
  otherInfo: Yup.string().required("Required"),
  activationPolicy: Yup.string().required("Required"),
});

export {
  localPlanSettingsSchema,
  multiversePlanSettingsSchema,
  planDetailsSchema
};