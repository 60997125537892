import React, { useEffect, useState } from 'react';
import { Layout } from "../../../ui";
import inf from "../../../assets/images/inf.png";
import styles from "../styles/style.module.css";
import { useLocation, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { validationSchema } from '../validations';
import { addSeoCountry, getSeoCountryDetails } from '../api';
import { toast } from 'react-toastify';

interface CountryDetail {
    countryId: string,
    title: string,
    keywords: string[],
    visibility: {
        app: boolean,
        website: boolean
    },
    description: string
}

function Seo() {
    const { id } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const countryName = queryParams.get("country");
    const [keywords, setKeywords] = useState<string[]>([]);
    const [keywordsTag, setKeywordsTag] = useState<string>('');

    const [getCountryDetail, setGetCountryDetail] = useState<CountryDetail>();

    useEffect(() => {
        getSeoCountryDetails(id as string).then((res: any) => {
            if (res.data) {
                setGetCountryDetail(res.data);
                if (res.data.keywords) setKeywords(res.data.keywords);
            }
        }).catch((error) => {
            toast.error(error?.data?.message || "Something went wrong!")
        });
    }, [id]);

    const formik = useFormik({
        initialValues: {
            title: getCountryDetail?.title || '',
            keywords: keywords || [],
            app: getCountryDetail?.visibility?.app || false,
            website: getCountryDetail?.visibility?.website || false,
            description: getCountryDetail?.description || ''
        },
        enableReinitialize: true, // Allow reinitialization when props change
        validationSchema: validationSchema,
        onSubmit: async (values, { setFieldError }) => {
            const payload: any = {
                countryId: id,
                title: values.title,
                keywords : !keywordsTag ? keywords : [...keywords, keywordsTag, ],
                visibility: {
                    app: values.app,
                    website: values.website
                },
                description: values.description
            };

            if (!payload.visibility.website) {
                setFieldError('website', 'Website is required');
            }
            if (!payload.visibility.app) {
                setFieldError('app', 'App is required');
            }
            if(!payload.visibility.app || !payload.visibility.website) return false
            toast.promise(
                addSeoCountry(payload),
                {
                    pending: {
                        render() {
                            return "Trying to add SEO...";
                        }
                    },
                    success: {
                        render({ data }: any) {
                            getSeoCountryDetails(id as string).then((res: any) => {
                                if (res.data) {
                                    setGetCountryDetail(res.data);
                                    if (res.data.keywords) setKeywords(res.data.keywords);
                                    setKeywordsTag('')
                                }
                            }).catch((error) => {
                                toast.error(error?.data?.message || "Something went wrong!")
                            });
                            return "SEO added successfully!";
                        }
                    },
                    error: {
                        render() {
                            return "Something went wrong!";
                        }
                    },
                }
            );
        },
    });

    const keyWordsHandleChange = (e: any) => {
        const value = e.target.value;
        if(!value.trim()) return false
        setKeywordsTag(value)
        if (value.includes(' ') || e.keyCode === 13) {
            setKeywords((prevKeywords) => [value, ...prevKeywords]);
            e.target.value = ""
            return false
        }   
    };

    return (
        <div>
            <Layout title={`SEO Template (${countryName})`}>
                <div className={styles.SeoOuter}>
                    <div className='row'>
                        <div className='col-md-8'>
                            <div className={styles.leftSeo}>
                                {/* <h4>SEO Template</h4> */}
                                <form onSubmit={formik.handleSubmit}>
                                    <div className={styles.FormGroup}>
                                        <label>Title</label>
                                        <input
                                            type='text'
                                            placeholder='SEO'
                                            name='title'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.title}
                                        />
                                        {formik.touched.title && formik.errors.title && (
                                            <div className={styles.error}>{formik.errors.title}</div>
                                        )}
                                    </div>
                                    <div className={styles.FormGroup}>
                                        <label>Enter Keywords</label>
                                        <input
                                            type='text'
                                            placeholder='Keywords...'
                                            // name='keywords'
                                            onChange={keyWordsHandleChange}
                                            onKeyDown={keyWordsHandleChange}
                                            // onBlur={formik.handleBlur}
                                            // value={formik.values.keywords}
                                        />
                                        {formik.touched.keywords && formik.errors.keywords && (
                                            <div className={styles.error}>{formik.errors.keywords}</div>
                                        )}
                                        <ul>
                                            {keywords.length > 0 && keywords.map((keyword: string, index: number) => (
                                                <li key={index}>
                                                    <span>{keyword} 
                                                         <button type="button" className={styles.close}
                                                         onClick={() => {
                                                            setKeywords(keywords.filter(item => item !== keyword))
                                                         }} >x</button>
                                                    </span>                                                    
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className={styles.FormGroup}>
                                        <label>Visibility</label>
                                        <div className={styles.customInput}>
                                            <span className='checkData'>Websites</span>
                                            <div className="form-check form-switch cst-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="flexSwitchCheckCheckedWebsite"
                                                    name='website'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    checked={formik.values.website}
                                                />
                                            </div>
                                        </div>
                                        {formik.touched.website && formik.errors.website && (
                                            <div className={styles.error}>{formik.errors.website}</div>
                                        )}
                                    </div>
                                    <div className={styles.FormGroup}>
                                        <label>Visibility</label>
                                        <div className={styles.customInput}>
                                            <span className='checkData'>Apps</span>
                                            <div className="form-check form-switch cst-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="flexSwitchCheckCheckedApp"
                                                    name='app'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    checked={formik.values.app}
                                                />
                                            </div>
                                        </div>
                                        {formik.touched.app && formik.errors.app && (
                                            <div className={styles.error}>{formik.errors.app}</div>
                                        )}
                                    </div>
                                    <div className={styles.FormGroup}>
                                        <label>Descriptions</label>
                                        <textarea
                                            placeholder='Enter Description here.......'
                                            name='description'
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.description}
                                        ></textarea>
                                        {formik.touched.description && formik.errors.description && (
                                            <div className={styles.error}>{formik.errors.description}</div>
                                        )}
                                    </div>
                                    <div className={styles.FormGroup}>
                                        <input type='submit' value="Save" />
                                    </div>
                                </form>
                            </div>
                        </div>
                        {/* <div className='col-md-5'>
                            <div className={styles.rightSeo}>
                                <div className={styles.infoIC}>
                                    <img src={inf} alt="info" />
                                </div>
                                <p>These questions can help gauge your experience and skills in
                                    various aspects of project management and technical tasks.
                                    Be prepared to provide specific examples from your past work to support your answers.</p>
                                <p>These questions can help gauge your experience and skills in
                                    various aspects of project management and technical tasks.
                                    Be prepared to provide specific examples from your past work to support your answers.</p>
                                <p>These questions can help gauge your experience and skills in
                                    various aspects of project management and technical tasks.
                                    Be prepared to provide specific examples from your past work to support your answers.</p>
                                <p>These questions can help gauge your experience and skills in
                                    various aspects of project management and technical tasks.
                                    Be prepared to provide specific examples from your past work to support your answers.</p>
                                <p>These questions can help gauge your experience and skills in
                                    various aspects of project management and technical tasks.
                                    Be prepared to provide specific examples from your past work to support your answers.</p>
                            </div>
                        </div> */}
                    </div>
                </div>
            </Layout>
        </div>
    );
}

export default Seo;
