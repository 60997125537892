import Logo from './logo.png';
import WhiteLogo from './logwhite.png';
import GoogleIcon from './google.png';
import FacebookIcon from './fb.png';
import AppleIcon from './apple.png';
import UpArrowIcon from './arrow.png';
import DefaultUser from './user.png';
import NotificationUser from './notification.png';
import RegisteredUserImage from './tbimg.png';
import ProfitUpIcon from './up.png';
import ProfitDownIcon from './dn.png';
import ThreeDotIcon from './more.png';
import EditIcon from './edit.png';
import UserImage from './loginleft.png';
import SearchIcon from './search.png';
import UploadIcon from './upload.png';
import TrashIcon from './trash.png';
import AdminNotificationIcon from './noti.png';
import DashboardSidebar from './sidebar/dashboard.png';
import UserSidebar from './sidebar/users.png';
import CMSSidebar from './sidebar/cms.png';
import PaymentsSidebar from './sidebar/payments.png';
import AnalyticsSidebar from './sidebar/analytics.png';
import NotificationSidebar from './sidebar/notifications.png';
import ProfileSidebar from './sidebar/profile.png';
import LogoutSidebar from './sidebar/logout.png';
import PromosSidebar from './sidebar/coupons_promos.png';
import LoyalityPointsSidebar from './sidebar/loyalityPoints.png';
import CouponsSidebar from './sidebar/coupons.png';
import PlansSidebar from './sidebar/plans.png';
import BlogsSidebar from './sidebar/blogs.png';
import CommbatchIcon from './combatch.png';
import HourGlassIcon from './time.png';


export const IMAGES = {
  Logo,
  WhiteLogo,
  GoogleIcon,
  FacebookIcon,
  AppleIcon,
  UpArrowIcon,
  DefaultUser,
  NotificationUser,
  RegisteredUserImage,
  ProfitUpIcon,
  ProfitDownIcon,
  ThreeDotIcon,
  SIDEBAR: {
    DashboardSidebar,
    UserSidebar,
    CMSSidebar,
    PaymentsSidebar,
    AnalyticsSidebar,
    NotificationSidebar,
    ProfileSidebar,
    LogoutSidebar,
    PromosSidebar,
    LoyalityPointsSidebar,
    CouponsSidebar,
    PlansSidebar,
    BlogsSidebar,
  },
  EditIcon,
  UserImage,
  SearchIcon,
  UploadIcon,
  TrashIcon,
  AdminNotificationIcon,
  CommbatchIcon,
  HourGlassIcon,
};
