import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import MarkdownIt from "markdown-it";
import MdEditor from "react-markdown-editor-lite";

import styles from "./style.module.css";
import { CMSTypes } from "../../interfaces";
import { FAQSchema } from "../../features/cms/validations";
import { CONSTANTS } from "../../lib/constants/messages";
import { uploadFile } from "../../features/blogs/api";
import { updateFAQ, createFAQ } from "../../features/cms/api";

interface PopupType {
  show: boolean;
  handleClose: () => void;
  getFaqs: () => void;
  faq: CMSTypes.Faq | null;
};

const CreateUpdateFAQPopup = (props: PopupType) => {

  const mdParser = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
  });

  const faqFormik = useFormik({
    initialValues: { question: "", answer: "", faqId: "", html: "" },
    onSubmit: (values) => {
      toast.promise(
        props.faq ? updateFAQ(values) : createFAQ(values),
        {
          pending: {
            render() {
              return props.faq ? CONSTANTS.FAQ.UPDATING : CONSTANTS.FAQ.CREATING;
            }
          },
          success: {
            render() {
              props.getFaqs();

              if (!props.faq) faqFormik.resetForm();

              props.handleClose();
              return props.faq ? CONSTANTS.FAQ.UPDATE_SUCCESS : CONSTANTS.FAQ.CREATE_SUCCESS;
            }
          },
          error: {
            render() {
              return props.faq ? CONSTANTS.FAQ.UPDATE_FAILED : CONSTANTS.FAQ.CREATE_FAILED;
            }
          }
        }
      )
    },
    validationSchema: FAQSchema,
  });


  const handleImageUpload = (file: any, callback: any) => {
    const reader = new FileReader()
    reader.onload = () => {

      const convertBase64UrlToBlob = (urlData: any) => {
        let arr = urlData.split(","), mime = arr[0].match(/:(.*?);/)[1]
        let bstr = atob(arr[1])
        let n = bstr.length
        let u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        return new Blob([u8arr], { type: mime })
      }
      convertBase64UrlToBlob(reader.result);

      const formData = new FormData();
      formData.append("file", file);

      uploadFile(formData).then(res => {
        callback(res.data.url);
      });
    }
    reader.readAsDataURL(file)
  }

  const closePopup = () => {
    props.handleClose();
    faqFormik.resetForm();
  };

  useEffect(() => {
    if (props.faq) {
      faqFormik.setValues({
        faqId: props.faq._id,
        question: props.faq.question,
        answer: props.faq.answer,
        html: props.faq.html || "",
      });
    }
  }, [props.faq])

  return (
    <Modal size="xl" show={props.show} onHide={props.handleClose}>
      <Modal.Header className={styles.modHead}>
        <Modal.Title>Create FAQ</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.faqContent}>
        <div className="mb-3">
          <label className="w-100 mb-1">
            Question
            {
              faqFormik.touched.question && faqFormik.errors.question ?
                <span className="error">{faqFormik.errors.question}</span>
                :
                null
            }
          </label>
          <input
            className={styles.input}
            type="text"
            placeholder="Question"
            name="question"
            onChange={faqFormik.handleChange}
            onBlur={faqFormik.handleBlur}
            value={faqFormik.values.question}
          />
        </div>

        <label className="w-100 mb-1">
          Answer
          {
            faqFormik.touched.answer && faqFormik.errors.answer ?
              <span className="error">{faqFormik.errors.answer}</span>
              :
              null
          }

        </label>
        <MdEditor
          value={faqFormik.values.answer || ""}
          className={styles.editor}
          renderHTML={(text) => mdParser.render(text)}
          onChange={({ text, html }) => {
            faqFormik.setFieldValue("answer", text);
            faqFormik.setFieldValue("html", html);
          }

          }
          onImageUpload={handleImageUpload}
          view={{ menu: true, md: true, html: false }}
        />
      </Modal.Body>
      <Modal.Footer className={styles.modFoter}>
        <button type="button" className={styles.close} onClick={closePopup}>Close</button>
        <button type="submit" className={styles.button} onClick={faqFormik.submitForm}>Save Changes</button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateUpdateFAQPopup;