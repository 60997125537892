import React from "react";
import { Modal, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useFormik } from "formik";

import styles from "./style.module.css";
import { IMAGES } from "../../../assets/images";
import { updateProfile } from "../../../features/profile/api";
import { ModalTypes } from "../../../interfaces";
import { UserTypes } from "../../../interfaces";
import { CONSTANTS } from "../../../lib/constants/messages";

const ProfileModal = (props: ModalTypes.ProfileModalType) => {
	const profileFormik = useFormik({
		initialValues: {
			fullName: props.user?.fullName,
			email: props.user?.email,
			address: props.user?.address
		},
		onSubmit: (values, { setSubmitting }) => {
			toast.promise(
				updateProfile(values as UserTypes.UpdateAdminPayload),
				{
					pending: {
						render() {
							return CONSTANTS.PROFILE.UPDATING;
						}
					},
					success: {
						render() {
							setSubmitting(false);

							profileFormik.resetForm();
							props.setProfileModal(false);
							return CONSTANTS.PROFILE.SUCCESS;
						}
					},
					error: {
						render() {
							setSubmitting(false);
							return CONSTANTS.PROFILE.FAILED;
						}
					}
				}
			);
		},
		enableReinitialize: true,
	});

	return (
		<Modal
			show={props.show}
			onHide={() => props.setProfileModal(false)}
		>
			<div>
				<Modal.Header className={styles.modalDialog} closeButton>
					<Modal.Title>Edit Profile</Modal.Title>
				</Modal.Header>
				<Modal.Body className={styles.modalContent}>
					<div className={styles.changePasswordModal}>
						<Form onSubmit={profileFormik.handleSubmit}>
							<div className={styles.editIamge}>

								<input type="file" />
								<span className={styles.PrfileIamge}><img src={IMAGES.UserImage} alt="threeDot" /></span>
								<label>Change Profile photo</label>
							</div>
							<div className={styles.formGroup}>
								<label>Name</label>
								<input
									type="text"
									name="fullName"
									placeholder="Name"
									value={profileFormik.values.fullName}
									onChange={profileFormik.handleChange}
									onBlur={profileFormik.handleBlur}
								/>
							</div>
							<div className={styles.formGroup}>
								<label>Email</label>
								<input
									type="text"
									placeholder="Email"
									name="email"
									value={profileFormik.values.email}
									onChange={profileFormik.handleChange}
									onBlur={profileFormik.handleBlur}
								/>
							</div>

							<div className={styles.formGroup}>
								<label>Address</label>
								<input
									type="text"
									placeholder="23 Main Street, Anytown, USA 12345"
									name="address"
									value={profileFormik.values.address}
									onChange={profileFormik.handleChange}
									onBlur={profileFormik.handleBlur}
								/>
							</div>
							<div className={styles.formGroup}>
								<input type="submit" value="Save" />
							</div>
						</Form>
					</div>
				</Modal.Body>
			</div>
		</Modal>
	);
};

export default ProfileModal;