import { API_ROUTES } from "./apiRoutes";
import { APP_ROUTES } from "./appRoutes";
import { LOCAL_CONSTANTS } from './constantsLocal';

const CMS_TYPE = {
	PRIVACY_POLICY: 1,
	TERMS_CONDITIONS: 2,
	CONTACT_US: 3,
	ABOUT_US: 4,
	HOW_TO_USE: 5,
	FAQ: 6,
	// SOCIAL_MEDIA: 7,
	REFUND_POLICY:7,
	SHIPPIN_POLICY: 8
};

const EDITOR_PLUGINS = [
	"header",
	"font-bold",
	"font-italic",
	"font-strikethrough",
	"list-unordered",
	"list-ordered",
	"clear",
	"tab-insert",
	"table",
	"block-quote"
];

const PROMO_TYPE = {
	1: "Fixed",
	2: "Percentage",
};

const COUPON_TYPE = {
	COUNTRIES: 1,
	REGION: 2,
};

const BLOG_STATUS: any = {
	1: "Pending",
	2: "Approved",
	3: "Rejected"
};

const BACKEND_BLOG_STATUS = {
	PENDING: 1,
	APPROVED: 2,
	REJECTED: 3,
};

const LOYALITY_POINT_TYPE = {
	SUBSEQUENT_PURCHASE: 1,
}

const ROLE_TYPE = {
	USER: 1,
	ADMIN: 2,
};

const BLOG_TYPE = {
	USER: 2,
	ADMIN: 1,
};


const BLOG_COMMENT_STATUS: any = {
	1: "Pending",
	2: "Approved",
	3: "Rejected"
};

const MONTH_NAMES = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December"
];

export {
	API_ROUTES,
	APP_ROUTES,
	CMS_TYPE,
	EDITOR_PLUGINS,
	PROMO_TYPE,
	COUPON_TYPE,
	LOYALITY_POINT_TYPE,
	BLOG_STATUS,
	BACKEND_BLOG_STATUS,
	ROLE_TYPE,
	BLOG_COMMENT_STATUS,
	BLOG_TYPE,
	MONTH_NAMES,
	LOCAL_CONSTANTS
};