import React, { useEffect, useState } from "react";

import styles from "../styles/profile.module.css";
import { UserTypes } from "../../../interfaces";
import { IMAGES } from "../../../assets/images";
import { Layout } from "../../../ui";
import { getProfile } from "../api";
import ProfileModal from "../../../ui/modals/Profile";
import PasswordModal from "../../../ui/modals/Password";


const Profile = () => {
	const [profile, setProfile] = useState<UserTypes.User>();
	const [showProfileModal, setProfileModal] = useState<boolean>(false);
	const [showPasswordModal, setPasswordModal] = useState<boolean>(false);

	useEffect(() => {
		getProfile().then(res => {
			setProfile(res.data);
		});
	}, []);

	return (
		<Layout title="Profile">
			<div className="ProfielView">
			<div className={styles.profilePage}>
				<div className="row">
					<div className="col-md-6">
						<div className={styles.profileLeft}>
							<div className={styles.profilelefttp}>
								<h4>Overview</h4>
								<img
									height={20}
									width={20}
									onClick={() => setProfileModal(!showProfileModal)}
									src={IMAGES.EditIcon}
									alt="edit"
								/>
							</div>
							<div className={styles.profileImage}>
								<span>
									<img src={IMAGES.UserImage} alt="threeDot" />
								</span>
								<div className={styles.profileData}>
									<h6>{profile?.fullName}</h6>
									<h5>{profile?.email}</h5>
									{/* <label>Licence : #45678</label> */}
								</div>

							</div>
							<ul>
								<li><span>Contact phone</span><label>{profile?.countryCode} {profile?.phoneNumber}</label></li>
								<li><span>Address</span><label>23 Main Street, Anytown, USA 12345</label></li>
								<li><span>Time Zone</span><label>Jakarta (GMT+7)</label></li>

							</ul>
						</div>
					</div>
					<div className="col-md-6">
						<div className={styles.profileRight}>
							<h4>Signin Method</h4>
							<form>
								<div className={styles.formGroup}>
									<label>Email Address</label>
									<div className={styles.InputState}>
										<span>eureka88@email.com</span>
									</div>
									{/* <input className={styles.input} type="text" placeholder="eureka88@email.com" /> */}
								</div>
								<div className={styles.formGroup}>
									<label>Password</label>
									<div className={styles.InputState}>
										<span>******************</span>
									</div>
									{/* <input className={styles.input} type="password" placeholder="******************" /> */}
									<button type="button" className={styles.changePassword} onClick={() => setPasswordModal(!showPasswordModal)}>Change password</button>
								</div>
								<div className={styles.secure}>
									<h6>Secure Your Account</h6>
									<a href="#">Enable</a>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			</div>
			
			<ProfileModal show={showProfileModal} setProfileModal={setProfileModal} user={profile} />
			<PasswordModal show={showPasswordModal} setPasswordModal={setPasswordModal} />
		
		</Layout>
	);
};

export default Profile;
