import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faWhatsapp, faYoutube } from "@fortawesome/free-brands-svg-icons";

import { toast } from "react-toastify";
import { update } from "../api";
import { ContactUsSchema } from "../validations";
import styles from "../styles/cms.module.css";
import { CMSTypes } from "../../../interfaces";
import { CMS_TYPE } from "../../../lib/constants";
import { CONSTANTS } from "../../../lib/constants/messages";
import { IMAGES } from "../../../assets/images";

interface Props {
  cms: CMSTypes.SpecificCMS;
  cmsType: number;
  loading: boolean;
  resetCMS: () => void;
};

const ContactUs = ({ cms, cmsType, loading, resetCMS }: Props) => {
  const [isEditor, toggleEditor] = useState<boolean>(false);

  const contactUsFormik = useFormik({
    initialValues: {
      phoneNumber: "",
      email: "",
      address: "",
      regionalphoneNumber: "",
      regionalemail: "",
      regionaladdress: "",
      facebookLink: "",
      youtubeLink: "",
      instagramLink: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      let fields = [];

      for (const [key, value] of Object.entries(values))
        fields.push({ name: key, value });

      let payload: any = { type: cmsType, fields };

      toast.promise(update(payload), {
        pending: {
          render() {
            return CONSTANTS.CMS.UPDATING;
          },
        },
        success: {
          render() {
            toggleEditor(false);

            resetCMS();
            return CONSTANTS.CMS.SUCCESS;
          },
        },
        error: {
          render() {
            setSubmitting(false);
            return CONSTANTS.CMS.FAILED;
          },
        },
      });
    },
    enableReinitialize: true,
    validationSchema: ContactUsSchema,
  });

  useEffect(() => {
    if (cms && cms.contactUsFields && cmsType === CMS_TYPE.CONTACT_US) {
      contactUsFormik.setValues({
        email: cms.contactUsFields.email,
        phoneNumber: cms.contactUsFields.phoneNumber,
        address: cms.contactUsFields.address,
        regionalemail: cms.contactUsFields.regionalemail,
        regionalphoneNumber:cms.contactUsFields.regionalphoneNumber,
        regionaladdress: cms.contactUsFields.regionaladdress,
        youtubeLink: cms.contactUsFields.youtubeLink,
        facebookLink: cms.contactUsFields.facebookLink,
        instagramLink: cms.contactUsFields.instagramLink,
      });
    }
  }, [cms, cmsType]);

  return (
    <div>
      <form
        onSubmit={contactUsFormik.handleSubmit}
      >
        <div className={styles.tabDtahd}>
          <h4>Contact Us</h4>
          <div>
            {isEditor && (
              <button type="submit" className="me-2">
                <img
                  width={100}
                  height={100}
                  src={IMAGES.EditIcon}
                  alt="defaultUser"
                />
                Update
              </button>
            )}
            <button type="button" onClick={() => toggleEditor(!isEditor)}>
              <img
                width={100}
                height={100}
                src={IMAGES.EditIcon}
                alt="defaultUser"
              />
              {isEditor ? "Cancel" : "Edit"}
            </button>
          </div>
        </div>

        {loading ? <p className={styles.loading}>Loading...</p> : null}

        {!loading && !isEditor &&
          <div className={styles.contactUS}>
            <ul>
              <li>
                <span>Company</span>
                <label>
                  <img
                    width={100}
                    height={100}
                    src={IMAGES.Logo}
                    alt="defaultUser"
                  />
                </label>
              </li>
              <li>
                <span>Contact phone</span>
                <label>
                  {cms &&
                    cms.contactUsFields &&
                    cms.contactUsFields.phoneNumber}
                </label>
              </li>
              <li>
                <span>Email </span>
                <label>
                  {cms && cms.contactUsFields && cms.contactUsFields.email}
                </label>
              </li>
              <li>
                <span>Address</span>
                <label>
                  {cms && cms.contactUsFields && cms.contactUsFields.address}
                </label>
              </li>




              <li>
                <span>Regional Contact phone</span>
                <label>
                  {cms &&
                    cms.contactUsFields &&
                    cms.contactUsFields.regionalphoneNumber}
                </label>
              </li>
              <li>
                <span>Regional Email </span>
                <label>
                  {cms && cms.contactUsFields && cms.contactUsFields.regionalemail}
                </label>
              </li>
              <li>
                <span>Regional Address</span>
                <label>
                  {cms && cms.contactUsFields && cms.contactUsFields.regionaladdress}
                </label>
              </li>




              <li>
                <span>Social Media Links </span>
                <label>
                  <div className={styles.Social}>
                    <a href="#">
                      <FontAwesomeIcon
                        size="lg"
                        className={styles.uploadButton}
                        icon={faWhatsapp}
                        color="white"
                      />
                    </a>

                    <a href={cms.contactUsFields?.instagramLink} target='_blank' rel="noreferrer">
                      <FontAwesomeIcon
                        size="lg"
                        className={styles.uploadButton}
                        icon={faInstagram}
                        color="white"
                      />
                    </a>
                    <a href={cms.contactUsFields?.youtubeLink} target='_blank' rel="noreferrer">
                      <FontAwesomeIcon
                        size="lg"
                        className={styles.uploadButton}
                        icon={faYoutube}
                        color="white"
                      />
                    </a>
                    <a href={cms.contactUsFields?.facebookLink} target='_blank' rel="noreferrer">
                      <FontAwesomeIcon
                        size="lg"
                        className={styles.uploadButton}
                        icon={faFacebook}
                        color="white"
                      />
                    </a>
                  </div>
                </label>
              </li>
            </ul>
          </div>
        }
        {isEditor && !loading ? (
          <>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className={styles.label}>
                    Email
                    {contactUsFormik.touched.email &&
                      contactUsFormik.errors.email ? (
                      <p className={styles.error}>
                        {contactUsFormik.errors.email}
                      </p>
                    ) : null}
                  </label>
                  <input
                    type="text"
                    className={styles.input}
                    placeholder="Email"
                    value={contactUsFormik.values.email}
                    name="email"
                    onChange={contactUsFormik.handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className={styles.label}>
                    Address
                    {contactUsFormik.touched.address &&
                      contactUsFormik.errors.address ? (
                      <p className={styles.error}>
                        {contactUsFormik.errors.address}
                      </p>
                    ) : null}
                  </label>
                  <input
                    type="text"
                    className={styles.input}
                    placeholder="Address"
                    value={contactUsFormik.values.address}
                    name="address"
                    onChange={contactUsFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6">
                <div className="form-group">
                  <label className={styles.label}>
                    Phone number
                    {contactUsFormik.touched.phoneNumber &&
                      contactUsFormik.errors.phoneNumber ? (
                      <p className={styles.error}>
                        {contactUsFormik.errors.phoneNumber}
                      </p>
                    ) : null}
                  </label>
                  <input
                    type="text"
                    className={styles.input}
                    placeholder="Phone number"
                    value={contactUsFormik.values.phoneNumber}
                    name="phoneNumber"
                    onChange={contactUsFormik.handleChange}
                  />
                </div>
              </div>
            </div>




            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className={styles.label}>
                    Regional Email
                    {contactUsFormik.touched.regionalemail &&
                      contactUsFormik.errors.regionalemail ? (
                      <p className={styles.error}>
                        {contactUsFormik.errors.regionalemail}
                      </p>
                    ) : null}
                  </label>
                  <input
                    type="text"
                    className={styles.input}
                    placeholder="Email"
                    value={contactUsFormik.values.regionalemail}
                    name="regionalemail"
                    onChange={contactUsFormik.handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className={styles.label}>
                    Regional Address
                    {contactUsFormik.touched.regionaladdress &&
                      contactUsFormik.errors.regionaladdress ? (
                      <p className={styles.error}>
                        {contactUsFormik.errors.regionaladdress}
                      </p>
                    ) : null}
                  </label>
                  <input
                    type="text"
                    className={styles.input}
                    placeholder="Address"
                    value={contactUsFormik.values.regionaladdress}
                    name="regionaladdress"
                    onChange={contactUsFormik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6">
                <div className="form-group">
                  <label className={styles.label}>
                    Regional Phone number
                    {contactUsFormik.touched.regionalphoneNumber &&
                      contactUsFormik.errors.regionalphoneNumber ? (
                      <p className={styles.error}>
                        {contactUsFormik.errors.regionalphoneNumber}
                      </p>
                    ) : null}
                  </label>
                  <input
                    type="text"
                    className={styles.input}
                    placeholder="Phone number"
                    value={contactUsFormik.values.regionalphoneNumber}
                    name="regionalphoneNumber"
                    onChange={contactUsFormik.handleChange}
                  />
                </div>
              </div>
            </div>

          </>
        ) : null}

        {
          isEditor ?
            <>
              <div className={`${styles.tabDtahd} mt-4`}>
                <h4>Social Link</h4>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className={styles.label}>
                      Youtube Link
                      {contactUsFormik.touched.youtubeLink &&
                        contactUsFormik.errors.youtubeLink ? (
                        <p className={styles.error}>
                          {contactUsFormik.errors.youtubeLink}
                        </p>
                      ) : null}
                    </label>
                    <input
                      type="text"
                      className={styles.input}
                      placeholder="Youtube Link"
                      value={contactUsFormik.values.youtubeLink}
                      name="youtubeLink"
                      onChange={contactUsFormik.handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className={styles.label}>
                      Facebook Link
                      {contactUsFormik.touched.facebookLink &&
                        contactUsFormik.errors.facebookLink ? (
                        <p className={styles.error}>
                          {contactUsFormik.errors.facebookLink}
                        </p>
                      ) : null}
                    </label>
                    <input
                      type="text"
                      className={styles.input}
                      placeholder="Facebook Link"
                      value={contactUsFormik.values.facebookLink}
                      name="facebookLink"
                      onChange={contactUsFormik.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className={styles.label}>
                      Instagram Link
                      {contactUsFormik.touched.instagramLink &&
                        contactUsFormik.errors.instagramLink ? (
                        <p className={styles.error}>
                          {contactUsFormik.errors.instagramLink}
                        </p>
                      ) : null}
                    </label>
                    <input
                      type="text"
                      className={styles.input}
                      placeholder="instagram link"
                      value={contactUsFormik.values.instagramLink}
                      name="instagramLink"
                      onChange={contactUsFormik.handleChange}
                    />
                  </div>
                </div>
              </div>
            </>
            :
            null
        }
      </form>
    </div>
  );
};

export default ContactUs;