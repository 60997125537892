import React, { useEffect, useRef, useState } from "react";
import MdEditor from "react-markdown-editor-lite";
import MarkdownIt from "markdown-it";
import Rating from "react-star-ratings";
import Select from "react-select";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";

import styles from "../styles/manage.module.css";
import { listCountries } from "../../promos/api";
import BlogsSchema from "../validations/blogs";
import { create, specificBlog, updateBlog, uploadFile } from "../api";
import { CONSTANTS } from "../../../lib/constants/messages";
import { APP_ROUTES, EDITOR_PLUGINS } from "../../../lib/constants";
import { Layout } from "../../../ui";
import { IMAGES } from "../../../assets/images";

const ManageBlog = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [countries, setCountries] = useState<{ value: string, label: string }[]>([]);
  const [blogImage, setBlogImage] = useState("");
  const [isMounted, setIsMounted] = useState(false);
  const fileInputRef = useRef<any>(null);

  const mdParser = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
  });

  const handleFileInputChange = (event: any) => {
    const fileList = event.target.files;

    if (!fileList[0]) {
      return;
    }

    const fileExtension = fileList[0].name.split(".").pop()?.toLowerCase() || "";

    const allowedExtensions = [".jpg", ".png"];

    const formData = new FormData();

    formData.append("file", fileList[0]);

    if (!allowedExtensions.includes(`.${fileExtension}`)) {
      toast.error("Only JPG and PNG files are allowed.");
      return;
    }

    toast.promise(
      uploadFile(formData),
      {
        pending: {
          render() {
            blogsFormik.setSubmitting(true);

            return CONSTANTS.IMAGE_UPLOAD.UPLOADING;
          },
        },
        success: {
          render({ data }) {
            blogsFormik.setSubmitting(false);

            blogsFormik.setFieldValue("image", data.data.fileName);
            setBlogImage(data.data.url);
            return CONSTANTS.IMAGE_UPLOAD.UPLOAD_SUCCESS;
          },
        },
        error: {
          render({ data }: any) {
            blogsFormik.setSubmitting(false);

            return CONSTANTS.IMAGE_UPLOAD.UPLOAD_FAILED;
          },
        },
      });
  };

  const handleFileUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };


  const blogsFormik = useFormik({
    initialValues: {
      title: "",
      rating: 0,
      country: [],
      topic: "",
      image: ""
    },
    onSubmit: (values, { setSubmitting }) => {
      const payload = {
        ...values,
        country: values.country.map((item: any) => item.value)
      };

      toast.promise(
        params.id ? updateBlog({ ...payload, blogId: params.id as string }) : create(payload),
        {
          pending: {
            render() {
              return params.id ? CONSTANTS.BLOGS.UPDATING : CONSTANTS.BLOGS.CREATING;
            },
          },
          success: {
            render() {
              setSubmitting(false);

              if (!params.id) {
                blogsFormik.resetForm();
              }

              navigate(APP_ROUTES.BLOGS);

              return params.id ? CONSTANTS.BLOGS.UPDATE_SUCCESS : CONSTANTS.BLOGS.CREATE_SUCCESS;
            },
          },
          error: {
            render({ data }: any) {
              setSubmitting(false);

              return params.id ? CONSTANTS.BLOGS.UPDATE_FAILED: CONSTANTS.BLOGS.CREATE_FAILED;
            },
          },
        });
    },
    validationSchema: BlogsSchema,
  });

  useEffect(() => {
    listCountries({ limit: 1000 }).then(({ data }) => {
      const countries = data.countries.map(item => ({ label: item.name, value: item._id }))
      setCountries(countries);
    });

    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (params.id && countries.length) {
      specificBlog(params.id as string).then(res => {
        const { data: { blog } } = res;
        let selectedCountries = [];

        for (const item of blog.country) {
          const existingCountry = countries.find(it => it.value === item);

          if (existingCountry) selectedCountries.push(existingCountry);
        }

        setBlogImage(blog.image);

        blogsFormik.setValues({
          title: blog.title,
          rating: blog.rating,
          country: selectedCountries as any,
          topic: blog.topic,
          image: blog.imageId,
        })
      });
    }

  }, [params, countries])

  return (
    <Layout title="Blogs">

      <div className={styles.container}>
        <h4 className={styles.heading}>{params.id ? "Edit" : "Add"} Blog Content</h4>
        <form>
          <div className="mb-3">
            <label className={styles.label}>
              Image
              {
                blogsFormik.touched.image && blogsFormik.errors.image ?
                  <span className="error">{blogsFormik.errors.image}</span>
                  :
                  null
              }
            </label>

            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <img width={80} height={80} src={blogImage.length ? blogImage : IMAGES.UploadIcon} alt="user" className={styles.blogImage} />
                <div className="ms-3">
                  <p className="mb-0 text-white">Add Blog Image</p>
                  <p className="mb-0 text-white">PNG or JPG</p>
                </div>
              </div>
              {isMounted && <FontAwesomeIcon
                size="lg"
                className={styles.uploadButton}
                icon={faUpload}
                color="white"
                onClick={handleFileUpload}
              />}

            </div>
            <input
              type="file"
              className={styles.file}
              ref={fileInputRef}
              onChange={handleFileInputChange}
            />
          </div>
          <div className="mb-3">
            <label className={styles.label}>
              Title
              {
                blogsFormik.touched.title && blogsFormik.errors.title ?
                  <span className="error">{blogsFormik.errors.title}</span>
                  :
                  null
              }
            </label>
            <input
              type="text"
              className={styles.input}
              onChange={blogsFormik.handleChange}
              name="title"
              value={blogsFormik.values.title}
              onBlur={blogsFormik.handleBlur}
            />
          </div>

          <div className="mb-3">
            <label className={styles.label}>
              Topic
              {
                blogsFormik.touched.topic && blogsFormik.errors.topic ?
                  <span className="error">{blogsFormik.errors.topic}</span>
                  :
                  null
              }
            </label>
            <MdEditor
              plugins={EDITOR_PLUGINS}
              className={styles.editor}
              renderHTML={(text) => mdParser.render(text)}
              onChange={({ text }) =>
                blogsFormik.setFieldValue("topic", text)
              }
              value={blogsFormik.values.topic || ""}
              view={{ menu: true, md: true, html: false }}
            />
          </div>

          <div className="mb-3">
            <label className={styles.label}>
              Rating
              {
                blogsFormik.touched.rating && blogsFormik.errors.rating ?
                  <span className="error">{blogsFormik.errors.rating}</span>
                  :
                  null
              }
            </label>
            <Rating
              rating={blogsFormik.values.rating}
              changeRating={(values) => blogsFormik.setFieldValue("rating", values)}
              numberOfStars={5}
              starDimension="30px"
              starRatedColor="#03FE9D"
              starHoverColor="#03FE9D"
            />
          </div>

          <div className="mb-3">
            <label className={styles.label}>
              Country
              {
                blogsFormik.touched.country && blogsFormik.errors.country ?
                  <span className="error">{blogsFormik.errors.country as string}</span>
                  :
                  null
              }
            </label>
            <Select
              options={countries as any}
              onChange={(value) => {
                blogsFormik.setFieldValue("country", value);
              }}
              value={blogsFormik.values.country}
              isClearable
              isMulti
            />
          </div>

          <button className={styles.submit} type="button" onClick={blogsFormik.submitForm}>{params.id ? "Update" : "Create"}</button>
        </form>
      </div>
    </Layout>
  );
};

export default ManageBlog;