import * as Yup from "yup";

const addUpsellValidation = Yup.object().shape({
  planName: Yup.string().required("Plan Name is required")
  .matches(/^[A-Za-z\s]+$/, 'Plan Name  contains only alphabetic characters')
  .max(25)
  .test('no-whitespace', 'Plan Name  should not start or end with whitespace', value => {
    if (value) {
      return value === value.trim();
    }
    return true;
  }),
  upsellDate: Yup.string().required("Upsell Date is required"),
  upsellAmount: Yup.string().required("Upsell Amount is required")
  .matches(/^[0-9\s]+$/, 'Upsell Amount contains only digits')
  .max(25)
  .test('no-whitespace', 'Upsell Amount should not start or end with whitespace', value => {
    if (value) {
      return value === value.trim();
    }
    return true;
  }),
  upsellAmountCurrency: Yup.string().required("Upsell Amount is required")
});

export default addUpsellValidation;
