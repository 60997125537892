import { UserTypes } from "../../../interfaces";
import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../lib/constants";


export const list = (params: any): Promise<any> => {
	return axios.get(
		API_ROUTES.USERS.EVENT_LIST,
		{ params }
	);
}
