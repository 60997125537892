import React from "react";
import { useFormik } from "formik";
import { useEffect } from "react";
import { toast } from "react-toastify";

import styles from "../styles/settings.module.css";
import { getLoyalityPoints, updateLoyalityPoints } from "../api";
import { LOYALITY_POINT_TYPE } from "../../../lib/constants";
import { CONSTANTS } from "../../../lib/constants/messages";
import { Layout } from "../../../ui";

const LoyalityPointsSettings = () => {

  const subsequentPointFormik = useFormik({
    initialValues: {
      subsequentPoint: 0,
      isEnabled: false,
      type: 0,
    },
    onSubmit: (values) => {
      const payload = {
        type: values.type,
        loyalityPoints: values.subsequentPoint,
        isEnabled: values.isEnabled,
      };

      toast.promise(
        updateLoyalityPoints(payload),
        {
          pending: {
            render() {
              return CONSTANTS.LOYALITY_POINT.UPDATING;
            },
          },
          success: {
            render() {
              return CONSTANTS.LOYALITY_POINT.UPDATE_SUCCESS;
            },
          },
          error: {
            render() {
              return CONSTANTS.LOYALITY_POINT.UPDATE_FAILED;
            },
          },
        });
    },
  });

  useEffect(() => {
    getLoyalityPoints().then(res => {
      const { loyalityPoints } = res.data;

      for (const item of loyalityPoints) {
        if (item.type === LOYALITY_POINT_TYPE.SUBSEQUENT_PURCHASE) {
          subsequentPointFormik.setValues({
            subsequentPoint: item.loyalityPoints,
            isEnabled: item.isEnabled,
            type: item.type,
          });
        }
      }
    });
  }, []);

  return (
    <Layout title="Loyality Points">
      <div className={styles.loyalityPoints}>
        <h3 className="mb-3">Loyality Points</h3>
        <div className={styles.loyaltyCards}>
          <div className="d-flex justify-content-between mb-2">
            <p>Subsequent Charges (%)</p>
            <label className={styles.switch}>
              <input
                type="checkbox"
                checked={subsequentPointFormik.values.isEnabled}
                name="isEnabled"
                onChange={subsequentPointFormik.handleChange}
              />
              <span className={`${styles.slider} ${styles.round}`}></span>
            </label>
          </div>
          <input
            className={styles.input}
            type="text"
            placeholder="Points"
            name="subsequentPoint"
            onChange={subsequentPointFormik.handleChange}
            onBlur={subsequentPointFormik.handleBlur}
            value={subsequentPointFormik.values.subsequentPoint}
          />

          <div className="d-flex justify-content-end">
            <button onClick={subsequentPointFormik.submitForm} className={styles.updatePoints} >Update</button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LoyalityPointsSettings;