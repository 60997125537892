import React from "react";
import { Modal, Form } from "react-bootstrap";
import { toast } from "react-toastify";

import styles from "../Profile/style.module.css";
import { useFormik } from "formik";
import { updateProfile } from "../../../features/profile/api";
import { CONSTANTS } from "../../../lib/constants/messages";
import passwordSchema from "./validation";
import { ModalTypes } from "../../../interfaces";

const PasswordModal = (props: ModalTypes.PasswordModalType) => {
	const profileFormik = useFormik({
		initialValues: {
			old: "",
			new: "",
			confirm: ""
		},
		onSubmit: (values, { setSubmitting }) => {
			const payload = {
				oldPassword: values.old as string,
				newPassword: values.new as string
			};
			toast.promise(
				updateProfile(payload),
				{
					pending: {
						render() {
							return CONSTANTS.PROFILE.UPDATING;
						}
					},
					success: {
						render() {
							setSubmitting(false);

							profileFormik.resetForm();
							props.setPasswordModal(false);
							return CONSTANTS.PROFILE.SUCCESS;
						}
					},
					error: {
						render(response: any) {
							setSubmitting(false);
							return response.data.data.message;
						}
					}
				}
			);
		},
		enableReinitialize: true,
		validationSchema: passwordSchema,
	});

	return (
		<Modal
			show={props.show}
			onHide={() => props.setPasswordModal(false)}
		>
			<div>
				<Modal.Header className={styles.modalDialog} closeButton>
					<Modal.Title>Change Password</Modal.Title>
				</Modal.Header>
				<Modal.Body className={styles.modalContent}>
					<div className={styles.changePasswordModal}>
						<Form onSubmit={profileFormik.handleSubmit}>
							<div className={styles.formGroup}>
								<label>Old Password</label>
								<input
									type="password"
									name="old"
									placeholder="Old Password"
									value={profileFormik.values.old}
									onChange={profileFormik.handleChange}
									onBlur={profileFormik.handleBlur}
								/>
							</div>
							<div className={styles.formGroup}>
								<label>New Password</label>
								<input
									type="password"
									placeholder="New Password"
									name="new"
									value={profileFormik.values.new}
									onChange={profileFormik.handleChange}
									onBlur={profileFormik.handleBlur}
								/>
							</div>

							<div className={styles.formGroup}>
								<label>Confirm Password</label>
								<input
									type="password"
									placeholder="Confirm Password"
									name="confirm"
									value={profileFormik.values.confirm}
									onChange={profileFormik.handleChange}
									onBlur={profileFormik.handleBlur}
								/>
							</div>
							<div className={styles.formGroup}>
								<input type="submit" value="Save" />
							</div>
						</Form>
					</div>
				</Modal.Body>
			</div>
		</Modal>
	);
};

export default PasswordModal;