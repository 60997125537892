import { useEffect, useState } from "react";
import styles from "../styles/style.module.css";
import { LOCAL_CONSTANTS } from "../../../lib/constants";
import { useNavigate, useParams } from "react-router-dom";
import { getConfigrations, putConfigrations } from "../api";
import { toast } from "react-toastify";

const PartnersConfigration = () => {
    const { partnerName, partnerWebsiteId } = useParams();
    const [show, setShow] = useState<boolean>(false);

    const [configrationsData, setConfigrationsData] = useState<any>({
        niyoCommission: 0
    })

    const navigate = useNavigate();

    const roles = window.localStorage.getItem("admin_roles")

    useEffect(() => {
        getConfigrations().then((data) => {
            setConfigrationsData((prevState: any) => ({
                ...prevState,
                niyoCommission: data?.data?.niyoCommission
            }));
        }).catch((data) => {
            toast.error(data?.data?.message || "Something went wrong!!")
        })
    }, [])

    return (
        <>
            {(LOCAL_CONSTANTS.ROLES.COMMBITZ_USER === Number(roles) && LOCAL_CONSTANTS.PARTNERS.NIYO === partnerName?.toLowerCase()) &&

                (
                    <div className={styles.total_count}>
                        <div className="configForm">
                            <label>Commision (%):</label>
                                    {show ? <> <input type="text"
                                        onChange={(e) => {
                                            const val = e.target.value;
                                            setConfigrationsData((prevState: any) => ({
                                                ...prevState,
                                                niyoCommission: val
                                            }));
                                        }}
                                        value={configrationsData?.niyoCommission} />
                                        <button className="editBtn" onClick={
                                            () => {
                                                if (configrationsData?.niyoCommission !== "" && !(/^[0-9\s]+$/).test(configrationsData?.niyoCommission)) {
                                                    return toast.error("Commission should be number!")
                                                }

                                                const payload = {
                                                    "niyoCommission": configrationsData?.niyoCommission ? Number(configrationsData?.niyoCommission) : 0
                                                }
                                                toast.promise(
                                                    putConfigrations(payload),
                                                    {
                                                        pending: {
                                                            render() {
                                                                return "Updating in progress...";
                                                            },
                                                        },
                                                        success: {
                                                            render({ data }) {
                                                                setConfigrationsData((prevState: any) => ({
                                                                    ...prevState,
                                                                    niyoCommission: data?.data?.niyoCommission
                                                                }));
                                                                setShow(false)
                                                                return "Successfully update!"
                                                            },
                                                        },
                                                        error: {
                                                            render(data: any) {
                                                                return data?.data?.data?.message[0] || data?.data?.message || "Somwthing went wrong!!";
                                                            },
                                                        },
                                                    });
                                            }
                                        }>Save</button></> :
                                        <>
                                            <span>{configrationsData?.niyoCommission}</span>
                                            <button className="editBtn" onClick={() => setShow(true)}>Edit</button>
                                        </>
                                    }
                        </div>
                    </div>
                )}
                 {(LOCAL_CONSTANTS.ROLES.COMMBITZ_USER === Number(roles) && LOCAL_CONSTANTS.PARTNERS.VFS === partnerName?.toLowerCase()) && <p style={{color:"#fff"}}> No configuration </p>}

            {(LOCAL_CONSTANTS.ROLES.NIYO_USER === Number(roles) && LOCAL_CONSTANTS.PARTNERS.NIYO === partnerName?.toLowerCase()) && (<><label style={{color:"#fff"}} >Commision (%):</label><span style={{color:"#fff"}}>{configrationsData?.niyoCommission}</span></>)}
        </>
    );
};

export default PartnersConfigration;
