import React, { useState } from "react";
import { useFormik } from "formik";
import MarkdownIt from "markdown-it";
import MdEditor from "react-markdown-editor-lite";

import { toast } from "react-toastify";
import { update } from "../api";
import { CMSSchema } from "../validations";
import styles from "../styles/cms.module.css";
import { CMSTypes } from "../../../interfaces";
import { CMS_TYPE } from "../../../lib/constants";
import { CONSTANTS } from "../../../lib/constants/messages";
import { IMAGES } from "../../../assets/images";

interface Props {
  cms: CMSTypes.SpecificCMS;
  cmsType: number;
  loading: boolean;
  resetCMS: () => void;
};

const PrivacyPolicy = ({ cms, cmsType, loading, resetCMS }: Props) => {
  const mdParser = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
  });

  const [isEditor, toggleEditor] = useState<boolean>(false);


  const cmsFormik = useFormik({
    initialValues: { content: cms && cms.content },
    onSubmit: (values, { setSubmitting }) => {
      const payload = { content: values.content as string, type: cmsType };

      toast.promise(
        update(payload),
        {
          pending: {
            render() {
              return CONSTANTS.CMS.UPDATING;
            },
          },
          success: {
            render() {
              toggleEditor(false);

              resetCMS();

              return CONSTANTS.CMS.SUCCESS;
            },
          },
          error: {
            render() {
              setSubmitting(false);

              return CONSTANTS.CMS.FAILED;
            },
          },
        });
    },
    enableReinitialize: true,
    validationSchema: CMSSchema,
  });

  return (
    <div>
      <form
        onSubmit={cmsFormik.handleSubmit}
      >
        <div className={styles.tabDtahd}>
          <h4>{cmsType === CMS_TYPE.PRIVACY_POLICY ? "Privacy Policy" : cmsType === CMS_TYPE.TERMS_CONDITIONS ? "Terms & Conditions" : cmsType === CMS_TYPE.ABOUT_US ? "About Us" : cmsType === CMS_TYPE.REFUND_POLICY ? "Refund Policy" : cmsType === CMS_TYPE.SHIPPIN_POLICY ? "Shipping Policy" : "How to use"}</h4>
          <div>
            {isEditor && (
              <button type="submit" className="me-2">
                <img
                  width={100}
                  height={100}
                  src={IMAGES.EditIcon}
                  alt="defaultUser"
                />
                Update
              </button>
            )}
            <button type="button" onClick={() => toggleEditor(!isEditor)}>
              <img
                width={100}
                height={100}
                src={IMAGES.EditIcon}
                alt="defaultUser"
              />
              {isEditor ? "Cancel" : "Edit"}
            </button>
          </div>
        </div>

        {loading ? <p className={styles.loading}>Loading...</p> : null}

        {!loading && <div className={styles.tabData}>
          {cms && cms.content && !isEditor && !loading ? (
            <div
              dangerouslySetInnerHTML={{
                __html: mdParser.render(cms.content),
              }}
            />
          ) : null}
          {cms && cms.content && isEditor ? (
            <MdEditor
              value={cmsFormik.values.content || ""}
              className={styles.editor}
              renderHTML={(text) => mdParser.render(text)}
              onChange={({ text }) =>
                cmsFormik.setFieldValue("content", text)
              }
              view={{ menu: true, md: true, html: false }}
            />
          ) : null}
          {isEditor &&
            cmsFormik.touched.content &&
            cmsFormik.errors.content ? (
            <p className={styles.error}>{cmsFormik.errors.content}</p>
          ) : null}
        </div>}
      </form>
    </div>
  );
};

export default PrivacyPolicy;